import React, { useState , useEffect} from "react";
import { FaFacebook, FaInstagram, FaLine } from 'react-icons/fa';
import $ from "jquery"; // Make sure jQuery is installed and properly imported
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FacebookEmbed } from 'react-social-media-embed';
import { FacebookProvider, Like , EmbeddedPost} from 'react-facebook';
import { useNavigate } from 'react-router-dom';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Button } from "bootstrap";


function BackupIkkiHomePage() {
    const [showLogin, setShowLogin] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [checkin, setCheckin] = useState("");
    const [checkout, setCheckout] = useState("");
    const [result, setResult] = useState([]);
    const [lineid , setLineid]= useState("");
    const [remark , setRemark]= useState("");

    const navigate = useNavigate();

    useEffect(() => {
      setShowLogin(true); // Set showLogin to true when component mounts
  }, []); 

    const handlepagelogin = () => {
      navigate('pagelogin');
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const form = $(e.target);
      $.ajax({
          type: "POST",
          url: form.attr("action"),
          data: form.serialize(),
          success(data) {
              try {
                  const parsedData = JSON.parse(data); // Parse the JSON response
                  setResult(parsedData); // Set the result to the token received
                  window.alert("คำขอถูกส่งเรียบร้อยแล้ว");
                  setName("");
                  setPhone("");
                  setCheckin("");
                  setCheckout("");
                  setLineid("");
                  setRemark("");
                  
              } catch (error) {
                  console.error("Error parsing response:", error);
              }
          },
          error(jqXHR, textStatus, errorThrown) {
              console.error("AJAX Error:", errorThrown);
          }
          
      });
      window.alert("คำขอถูกส่งเรียบร้อยแล้ว");
      setName("");
      setPhone("");
      setCheckin("");
      setCheckout("");
      setLineid("");
      setRemark("");
      
  };
  

  return (
    <div>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: "#FFC6C6" ,Color: "white"}}>
        <div className="container">
          <a className="navbar-brand" href=""> <img src="https://img5.pic.in.th/file/secure-sv1/logoacf5d83ccd5bb7b2.png" style={{width:"50px"}} alt="Description of your image" className="img-fluid rounded" /></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0" >
                 
                      <button className="nav-item" style={{ color: "white" ,background:"pink" , padding:"5px" , borderRadius: "20px", borderColor: "white"}} onClick={handlepagelogin}>
                          LOGIN
                      </button>
                 
              </ul>
          </div>
        </div>
      </nav>

      {/* Page content */}
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-8">
            {/* Post content */}
            <article>
              {/* Post header */}
              <header className="mb-4">
                {/* Post title */}
                <h1 className="fw-bolder mb-1">ยินดีต้อนรับเข้าสู่ IKKI CAT HOTEL</h1>
        
                <a className="badge bg-secondary text-decoration-none link-light" href="#!">Cathotel</a>
                <a className="badge bg-secondary text-decoration-none link-light" href="#!">Bangkok</a>
              </header>
              {/* Preview image figure */}
              <figure className="mb-4"><img className="img-fluid rounded" src="https://www.ikki-cathotel-bangkok.com/images/435499065_122105697278261691_9176585767387185556_n.jpg" alt="..." /></figure>
              {/* Post content */}
              <section className="mb-5">
                <p className="fs-5 mb-4">

                        <h1>โรงแรมแมวกรุงเทพ IKKI Cat Hotel: "นุดท่องเที่ยวสุดสบาย นายเอนกายพักที่นี่"
                            รับฝากแมว รับดูแลแมว รายวัน รายเดือน  
                            ห้องแอร์24ชม. สะอาด ปลอดภัย ไม่ขังกรง
                            ชมวิวชมสวนได้ทั้งวัน เริ่มต้นเพียง 350/วัน</h1>
                                                    
                        การเดินทางเพื่อหาที่พักให้กับน้องแมวของคุณไม่ต้องเป็นปัญหาอีกต่อไป! โรงแรมแมวของเราพร้อมที่จะต้อนรับน้องแมวของคุณด้วยบริการที่ดีเยี่ยมและสภาพแวดล้อมที่เหมาะสมสำหรับการพักผ่อนของพวกเขา

                        บริการและสิ่งอำนวยความสะดวก
                        ห้องพักที่สะอาดและปลอดภัย: ทุกห้องพักได้รับการทำความสะอาดอย่างละเอียดเพื่อให้แมวของคุณได้รับการดูแลอย่างดีที่สุด นอกจากนี้เรายังมีมาตรการความปลอดภัยที่สูงมากเพื่อให้คุณมั่นใจในการเข้าพัก
                        บริการอาหารที่เหมาะสม: อาหารที่เราให้บริการถูกจัดทำโดยเฉพาะเพื่อตอบสนองความต้องการทางสุขภาพและความอร่อยของน้องแมวของคุณ
                        กิจกรรมและบันเทิง: เพื่อให้น้องแมวของคุณไม่เหงา โรงแรมของเรามีกิจกรรมและบันเทิงหลากหลาย เช่น พื้นที่เล่นเกมและโซนผ่อนคลาย
                        บรรยากาศ
                        สิ่งอำนวยความสะดวกแบบสปา: ทำไมถึงไม่ให้น้องแมวของคุณได้รับประสบการณ์ที่ผ่อนคลายด้วยการเข้ารับบริการสปาที่โรงแรมของเรา? มีการมอบบริการที่นี่เพื่อให้น้องแมวของคุณได้รับการดูแลอย่างทั่วถึง
                        พื้นที่สุนัขส่วนตัว: ไม่ต้องกังวลเกี่ยวกับการพบกับน้องสุนัขอื่น ๆ ที่นี่ เรามีพื้นที่ส่วนตัวที่จะให้แก่น้องแมวของคุณเพื่อให้พวกเขาได้สนุกไปกับการผ่อนคลายของตัวเอง
                        บริการดูแลแมวที่ปรับตัวได้
                        เราเข้าใจว่าทุกตัวน้องแมวมีความต้องการและลักษณะที่แตกต่างกัน ดังนั้นเรามีทีมงานผู้เชี่ยวชาญที่พร้อมที่จะปรับการดูแลและบริการให้เหมาะกับความต้องการของแมวของคุณ

                        สำหรับเจ้าของน้องแมว
                        เราไม่เพียงแต่ให้บริการที่ดีต่อน้องแมวของคุณ แต่ยังให้บริการที่ดีต่อคุณด้วย ทีมงานของเราพร้อมที่จะช่วยเสมอ เพื่อให้คุณได้รับประสบการณ์การเข้าพักที่สุดพร้อมน้องแมวของคุณ

                        ที่โรงแรมแมวของเรา ความสุขและความปลอดภัยของน้องแมวคือลำดับแรกและเราพร้อมที่จะทำให้ทุกความฝันของคุณและน้องแมวเป็นจริง! มาพักผ่อนที่โรงแรมแมวของเราและสนุกไปกับประสบการณ์ที่ดีที่สุดสำหรับน้องแมวของคุณ

                      </p>
               
              </section>
            </article>
            {/* Comments section */}
            <section className="mb-5">
              <div className="card bg-light">
                <div className="card-body">
                  {/* Comment form */}
                  <div className="col">
                  <div style={{ textAlign: 'center' }}>
                    <h1>สนใจเข้าพัก</h1>
                </div>
                
                <div className='row mb-3'>
                    <form
                    
                        action="https://ikki-cathotel-bangkok.com/php-ikkicathotel/serverrequirement.php"
                      //  action="http://localhost/react-php-ikkicathotel/PHP_REACT_POST/react-project/PHP/serverrequirement.php"
                        method="post"
                        onSubmit={handleSubmit}
                    >
                        <label htmlFor="name"style={{ width: '10%', padding: '2px', margin: '1px' }}>ชื่อ: </label>
                        <input style={{ width: '80%', padding: '2px', margin: '1px' }}
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <br />

                        <label htmlFor="phone" style={{ width: '10%', padding: '2px', margin: '1px' }}>เบอร์โทร: </label>
                        <input style={{ width: '80%', padding: '2px', margin: '1px' }}
                            type="text"
                            id="phone"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <br />
                        <label htmlFor="lineid" style={{ width: '10%', padding: '2px', margin: '1px' }}>Line ID: </label>
                        <input style={{ width: '80%', padding: '2px', margin: '1px' }}
                            type="text"
                            id="lineid"
                            name="lineid"
                            value={lineid}
                            onChange={(e) => setLineid(e.target.value)}
                        />
                        <br />
                        <label htmlFor="remark" style={{ width: '10%', padding: '2px', margin: '1px' }}>รายละเอียด </label>
                        <input style={{ width: '80%', padding: '2px', margin: '1px' }}
                            type="text"
                            id="remark"
                            name="remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                        />
                        <div style={{ display: 'flex', alignItems: 'center' , justifyContent: 'space-between' ,marginTop: '1%' ,marginLeft: '10%',marginRight: '10%'}}>
                          <div style={{ width: '30%' }}>
                              <label htmlFor="checkin" style={{ padding: '2px', margin: '1px' }}>วันที่เข้าพัก: </label>
                              <DatePicker 
                                  id="checkin"
                                  name="checkin"
                                  selected={checkin}
                                  onChange={(date) => setCheckin(date)}
                                  className="form-control" // Apply custom CSS class to the date picker
                              />
                          </div>
                          <div style={{ marginLeft: '32px', width: '30%' }}>
                              <label htmlFor="checkout" style={{ padding: '2px', margin: '1px' }}>วันที่ออก: </label>
                              <DatePicker
                                  id="checkout"
                                  name="checkout"
                                  selected={checkout}
                                  onChange={(date) => setCheckout(date)}
                                  startDate={checkin}
                                  endDate={checkout}
                                  className="form-control" // Apply custom CSS class to the date picker
                              />
                          </div>
                      </div>
                      
                        
                       
                        <br />
                        <div style={{ textAlign: 'center' }}>
                         <button type="submit">Submit</button>
                        </div>
                  
                    </form>
                </div>
                  </div>

                </div>
              </div>
            </section>
          </div>

          {/* Side widgets */}
          <div className="col-lg-4">
            {/* Search widget */}
            <div className="card mb-4">
              <div className="card-header">Facebook Page</div>
              <a href="https://www.facebook.com/IKKI.CatHotel.Bangkok/">
                  <img src="https://www.ikki-cathotel-bangkok.com/images/pageikkicathotelbangkok.jpg" alt="ikkicathotelbestcathotelinbangkok" class="img-fluid rounded" />
              </a>
                <FacebookProvider appId="https://www.facebook.com/?ref=homescreenpwa">
                    <Like href="https://www.facebook.com/IKKI.CatHotel.Bangkok/" colorScheme="dark" showFaces share />
                 </FacebookProvider>
            </div>

            {/* Categories widget */}
            <div className="card mb-4">
              <div className="card-header">LINE</div>
              <div className="card-body">
                <div className="row">
                <img src="https://www.ikki-cathotel-bangkok.com/images/lineqrikkicathotelbangkok.gif" style={{width:"500px"}} alt="ikkicathotelbestcathotelinbangkok" className="img-fluid rounded" />
                
                </div>
              </div>
            </div>

            {/* Side widget */}
            <div className="card mb-4">
              <div className="card-header">แผนที่ โรงแรมแมวกรุงเทพ Ikki Cat Hotel</div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.395879790858!2d100.55197027586644!3d13.875261294421641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e283f2397d1d1f%3A0xb06288e05079b200!2z4LmC4Lij4LiH4LmB4Lij4Lih4LmB4Lih4Lin4LiB4Lij4Li44LiH4LmA4LiX4LieIElLS0kgQ2F0IEhvdGVsIEJhbmdrb2s!5e0!3m2!1sth!2sth!4v1714535256809!5m2!1sth!2sth" width="auto" height="450" ></iframe>
              </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="py-5 bg-pink" style={{backgroundColor: "#FFC6C6" ,Color: "white"}}>
        <div className="container"><p className="m-0 text-center text-white">Copyright &copy; Ikki Cat Hotel 2024</p></div>
      </footer>

 
    </div>
  );
}

export default BackupIkkiHomePage;

