import React, { useState } from "react";
import $ from "jquery";

const CancelCalendar = ({checkindate , checkoutdate , roomname , bookingid } ) => {
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formSubmitted) { // Check if form has already been submitted
            setFormSubmitted(true); // Set formSubmitted to true to indicate form submission
            const form = $(e.target);
            $.ajax({
                type: "POST",
                url: form.attr("action"),
                data: form.serialize(),
                success: (data) => {
                    window.alert("ทำการจองสำเร็จ");
                },
                error: (xhr, status, error) => {
                    window.alert("ทำการจองไม่ได้");
                }
            });
        }
    };

    return (
        <div className="row">
            <form
                action="https://ikki-cathotel-bangkok.com/php-ikkicathotel/serverroomscancel.php"
                method="post"
                onSubmit={handleSubmit}
            >
                <input type="hidden" id="checkindate" name="checkindate" value={checkindate} />
                <input type="hidden" id="checkoutdate" name="checkoutdate" value={checkoutdate} />
                <input type="hidden" id="roomname" name="roomname" value={roomname} />
                <input type="hidden" id="bookingid" name="bookingid" value={bookingid} />
                <br />
                <div style={{ textAlign: 'center' }}>
                <center><button type="submit" style={{   borderRadius: '10px', margin: '10px', padding: '10px'  }}>ยกเลิกการล๊อค</button></center> 
                    
                </div>
            </form>    
        </div>
    )
}

export default CancelCalendar;
