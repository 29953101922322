import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CalendarShow = () => {
    const [startMonth, setStartMonth] = useState(new Date());
    const [endMonth, setEndMonth] = useState(new Date());
    const [datalist, setDatalist] = useState([]);

    useEffect(() => {
        Axios.get('https://ikki-cathotel-bangkok.com/php-ikkicathotel/serverroomscontrol.php')
            .then((response) => {
                setDatalist(response.data);
            })
            .catch((error) => {
                console.error("Error fetching booking data:", error);
            });
    }, []);

    const columns = datalist.length > 0 ? Object.keys(datalist[0]).filter(column => column !== 'id') : [];

    const filterByMonthRange = (data, start, end) => {
        return data.filter(entry => {
            const date = new Date(entry.date);
            return date >= new Date(start.getFullYear(), start.getMonth(), 1) &&
                   date <= new Date(end.getFullYear(), end.getMonth() + 1, 0);
        });
    };

    const filteredData = filterByMonthRange(datalist, startMonth, endMonth);

    const countEmptyEntriesByRow = () => {
        return filteredData.map((entry) => {
            let emptyEntryCount = 0;
            columns.forEach((columnName) => {
                if (!entry[columnName]) {
                    emptyEntryCount++;
                }
            });
            return emptyEntryCount;
        });
    };

    const emptyEntryCounts = countEmptyEntriesByRow();
    const labels = filteredData.map((entry) => entry[columns[0]]); // Assuming 'columns[0]' is the date

    const data = {
        labels,
        datasets: [
            {
                label: 'Number of Empty Entries',
                data: emptyEntryCounts,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Empty Room Counts by Date',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div>
            <div className="mb-3">
                <DatePicker
                    selected={startMonth}
                    onChange={(date) => {
                        setStartMonth(date);
                        setEndMonth(date);
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    customInput={
                        <input
                            type="text"
                            value={startMonth ? startMonth.toLocaleDateString('en-US', { month: '2-digit', year: 'numeric' }) : ''}
                        />
                    }
                />
                <FaCalendarAlt />
            </div>

            <div className="mb-3">
                <DatePicker
                    selected={endMonth}
                    readOnly
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    customInput={
                        <input
                            type="hidden"
                            value={endMonth ? endMonth.toLocaleDateString('en-US', { month: '2-digit', year: 'numeric' }) : ''}
                        />
                    }
                />
            </div>

            {filteredData.length > 0 && (
                <Bar data={data} options={options} />
            )}
        </div>
    );
};

export default CalendarShow;
