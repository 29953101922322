import React, { useState, useEffect } from "react";
import $ from "jquery";

const BookingPost = ({ checkindate, checkoutdate, roomname, bookingid, phonenumber, name, surname, idnumber ,days , address }) => {
    const [priceperday, setPriceperday] = useState(0);
    const [rentalprice , setRentalprice] = useState(0);
    const [fullprice, setFullprice] = useState(0);
    const [transportationprice, setTransportationprice] = useState(0);
    const [otherprice, setOtherprice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [priceVat, setPriceVat] = useState(0);
    const [price, setPrice] = useState(0);
    const [totalprice, setTotalPrice] = useState(0);
    const [remark, setRemark] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const isSubmitDisabled = formSubmitted || bookingid.length < 27;
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formSubmitted) { // Check if form has already been submitted
            setFormSubmitted(true); // Set formSubmitted to true to indicate form submission
            const form = $(e.target);
            $.ajax({
                type: "POST",
                url: form.attr("action"),
                data: form.serialize(),
                success: (data) => {
                    window.alert("ทำการจองสำเร็จ");
                },
                error: (xhr, status, error) => {
                    window.alert("ทำการจองไม่ได้");
                }
            });
        }
    };

    useEffect(() => {
        
        setFullprice(days*priceperday);
        // const calculatedPriceVat = (fullprice - fullprice*discount/100) * 0.07;
        // setPriceVat(calculatedPriceVat.toFixed(2));
        // setPrice((fullprice - fullprice*discount/100 - calculatedPriceVat).toFixed(2));
    }, [ days , priceperday ]);
    useEffect(() => {
        setTotalPrice( (fullprice + +transportationprice + +otherprice)*(1-discount/100));
    }, [fullprice , transportationprice , otherprice , discount]);

    useEffect(() => {
        setPrice((totalprice)/1.07);
    }, [totalprice]);

    useEffect(() => {
        setPriceVat((price)*0.07);
    }, [price]);


 

    return (
        <div className="row">
            <form
                action="https://ikki-cathotel-bangkok.com/php-ikkicathotel/serverbookingcreate.php"
                method="post"
                onSubmit={handleSubmit}
            >
                <input type="hidden" id="checkindate" name="checkindate" value={checkindate}  />
                <input type="hidden" id="checkoutdate" name="checkoutdate" value={checkoutdate} />
                <input type="hidden" id="roomname" name="roomname" value={roomname} />
                <input type="hidden" id="bookingid" name="bookingid" value={bookingid} />
                <input type="hidden" id="phonenumber" name="phonenumber" value={phonenumber} />
                <input type="hidden" id="name" name="name" value={name} />
                <input type="hidden" id="surname" name="surname" value={surname} />
                <input type="hidden" id="idnumber" name="idnumber" value={idnumber} />
                <input type="hidden" id="days" name="days" value={days} />
                <input type="hidden" id="address" name="address" value={address} />
                <label htmlFor="days" style={{ width: '15%', padding: '2px', margin: '1px' }}>DAYS: {days}</label>
                <br/>
                <label htmlFor="priceperday" style={{ width: '15%', padding: '2px', margin: '1px' }}>ราคาต่อวัน: </label>
                <input style={{ width: '80%', padding: '2px', margin: '1px' }}
                    type="number"
                    id="priceperday"
                    name="priceperday"
                    value={priceperday}
                    onChange={(e) => setPriceperday(e.target.value)}
                />
                <br />
                <label htmlFor="fullprice" style={{ width: '15%', padding: '2px', margin: '1px' }}>ราคาที่พัก: </label>
                <input style={{ width: '80%', padding: '2px', margin: '1px' }}
                    type="number"
                    id="fullprice"
                    name="fullprice"
                    value={fullprice}
                    readOnly // Make it read-only
                />
                <br />
                <label htmlFor="transportationprice" style={{ width: '15%', padding: '2px', margin: '1px' }}>ค่าเดินทาง: </label>
                <input style={{ width: '80%', padding: '2px', margin: '1px' }}
                    type="number"
                    id="transportationprice"
                    name="transportationprice"
                    value={transportationprice}
                    onChange={(e) => setTransportationprice(e.target.value)}
                />
                <br />
                <label htmlFor="otherprice" style={{ width: '15%', padding: '2px', margin: '1px' }}>อื่นๆ: </label>
                <input style={{ width: '80%', padding: '2px', margin: '1px' }}
                    type="number"
                    id="otherprice"
                    name="otherprice"
                    value={otherprice}
                    onChange={(e) => setOtherprice(e.target.value)}
                />
                <br />

                {/* Discount input */}
                <label htmlFor="discount" style={{ width: '15%', padding: '2px', margin: '1px' }}>เปอร์เซนต์ส่วนลด: </label>
                <input style={{ width: '80%', padding: '2px', margin: '1px' }}
                    type="number"
                    id="discount"
                    name="discount"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                />
                <br />

                {/* VAT input */}
                <label htmlFor="priceVat" style={{ width: '15%', padding: '2px', margin: '1px' }}>VAT: </label>
                <input style={{ width: '80%', padding: '2px', margin: '1px' }}
                    type="number"
                    id="priceVat"
                    name="priceVat"
                    value={priceVat}
                    readOnly // Make it read-only
                />
                <br />

                {/* Total input */}
                <label htmlFor="price" style={{ width: '15%', padding: '2px', margin: '1px' }}>ราคาก่อน VAT: </label>
                <input style={{ width: '80%', padding: '2px', margin: '1px' }}
                    type="number"
                    id="price"
                    name="price"
                    value={price}
                    readOnly 
                />
                <br />
                <label htmlFor="totalprice" style={{ width: '15%', padding: '2px', margin: '1px' }}>ราคาหลัง VAT: </label>
                <input style={{ width: '80%', padding: '2px', margin: '1px' }}
                    type="number"
                    id="totalprice"
                    name="totalprice"
                    value={totalprice}
                    readOnly
                />

                <br />

                {/* Remark input */}
                <label htmlFor="remark" style={{ width: '15%', padding: '2px', margin: '1px' }}>เพิ่มเติม: </label>
                <input style={{ width: '80%', padding: '2px', margin: '1px' }}
                    type="text"
                    id="remark"
                    name="remark"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                />
                <br />
      
                
                <div style={{ textAlign: 'center' }}>
                    <button type="submit" disabled={isSubmitDisabled}>Submit</button>
                </div>
            </form>
        </div>
    )
}

export default BookingPost;
