import React, { useState } from 'react';
import $ from "jquery"; 

const UpdateStatus = ({ id, initialStatus }) => {
    const [status, setStatus] = useState(initialStatus);

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success: (data) => {
                window.alert("แก้ไขข้อมูลเรียบร้อย");
            },
            error: (xhr, status, error) => {
                window.alert("แก้ไขข้อมูลไม่ได้");
            }
        });
    };

    return (
        <div className="row">
            <div className="col">
                <form
                    action="https://ikki-cathotel-bangkok.com/php-ikkicathotel/serverbookingupdate.php"
                    method="post"
                    onSubmit={handleSubmit}
                    style={{ width: '90%' }}
                >
                    <input
                        type="hidden"
                        id="id"
                        name="id"
                        value={id}
                        readOnly
                        style={{ width: '60%', marginLeft: '20%' }}
                    />
                       <input
                        type="hidden"
                        id="status"
                        name="status"
                        value={status}
                        readOnly
                        style={{ width: '60%', marginLeft: '20%' }}
                    />

           
                    <center><button type="submit" style={{ borderRadius: '10%', backgroundColor: 'yellow' }}>{initialStatus}</button></center> 
                </form>
                <select
                        id="status"
                        name="status"
                        value={status}
                        onChange={handleStatusChange}
                        style={{ width: '100%' }}
                    >
                        <option value="pending">Pending</option>
                        <option value="confirmed">Confirmed</option>
                        <option value="checkin">Check-in</option>
                        <option value="checkout">Check-out</option>
                    </select>

            </div>
        </div>
    );
}

export default UpdateStatus;
