import React, { useState} from 'react';
import Manubar from '../components/Manubar';
import $ from "jquery"; 
import ManuHot from '../components/Manuhot';
const MemberCreate = () => {

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [catsnumber, setCatsnumber] = useState(0);
  const [score, setScore] = useState(0);
  const [address, setAddress] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [remark, setRemark] = useState('');
  const [idnumber, setIdnumber] = useState('');

  const [lineid, setLineid] = useState('');

  const [result, setResult] = useState([]);




  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    const form = $(e.target); // Correct the 'target' property
    $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: form.serialize(),
        success: (data) => {
            setResult(data); // Assuming setResult is defined elsewhere
            window.confirm("ลงทะเบียนสำเร็จ"); // Registration successful
        },
        error: (jqXHR, textStatus, errorThrown) => {
            console.error("AJAX Error:", textStatus, errorThrown); // Log error information
            alert("An error occurred during the registration process. Please try again.");
        }
    });
}


  return (

    <div className="row">
            <div className="col-6 col-md-2" style={{ backgroundColor: 'pink' }}>
                <Manubar/>
            </div>
            <div className="col" style={{ backgroundColor: 'white'}}>
            <ManuHot/>

                <div className="container-sm p-4 rounded bg-light shadow d-flex flex-column align-items-center" style={{ marginRight: "10%" , marginLeft: "10%" ,width:"80%" }}>
                <center><h1>ลงทะเบียน สมาชิก </h1></center>
                    <form
                        action="https://www.ikki-cathotel-bangkok.com/php-ikkicathotel/severmembercreate.php"
                        method="post"
                        onSubmit={handleSubmit}
                        style={{width: '90%'}}
                    >
                    
                      <label htmlFor="name"style={{ width: '30%', padding: '2px', margin: '1px', marginLeft: '10%',fontSize:'25px'}}>ชื่อ: </label>
                        <input
                            style={{width: '50%'}}
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <br />
                        <label htmlFor="surname" style={{ width: '30%', padding: '2px', margin: '1px', marginLeft: '10%',fontSize:'25px' }}>นามสกุล: </label>
                        <input
                            style={{width: '50%'}}
                            type="surname"
                            id="surname"
                            name="surname"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                        />
                        <br />
                        <label htmlFor="idnumber" style={{ width: '30%', padding: '2px', margin: '1px', marginLeft: '10%' ,fontSize:'25px' }}>รหัสประจำดัวผู้เสียภาษี: </label>
                        <input
                            style={{width: '50%'}}
                            type="idnumber"
                            id="idnumber"
                            name="idnumber"
                            value={idnumber}
                            onChange={(e) => setIdnumber(e.target.value)}
                        />
                        <br />
                        <label htmlFor="phonenumber" style={{ width: '30%', padding: '2px', margin: '1px' , marginLeft: '10%',fontSize:'25px'}}>เบอร์โทรศัพท์: </label>
                        <input
                            style={{width: '50%'}}
                            type="text"
                            id="phonenumber"
                            name="phonenumber"
                            value={phonenumber}
                            onChange={(e) => setPhonenumber(e.target.value)}
                        />
                        <br />
                        <label htmlFor="catsnumber" style={{width: '30%', padding: '2px', margin: '1px', marginLeft: '10%',fontSize:'25px'}}>จำนวนน้องแมว: </label>
                        <input
                            style={{width: '50%'}}
                            type="text"
                            id="catsnumber"
                            name="catsnumber"
                            value={catsnumber}
                            onChange={(e) => setCatsnumber(e.target.value)}
                        />
                        <br />
                        <label htmlFor="address" style={{ width: '30%', padding: '2px', margin: '1px', marginLeft: '10%',fontSize:'25px'}}>ที่อยู่: </label>
                        <input
                            style={{width: '50%'}}
                            type="text"
                            id="address"
                            name="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                        <br />
                        <label htmlFor="lineid" style={{width: '30%', padding: '2px', margin: '1px' , marginLeft: '10%',fontSize:'25px'}}>LINE ID: </label>
                        <input
                            style={{width: '50%'}}
                            type="text"
                            id="lineid"
                            name="lineid"
                            value={lineid}
                            onChange={(e) => setLineid(e.target.value)}
                        />
                        <br />
       
                        <label htmlFor="remark" style={{width: '30%', padding: '2px', margin: '1px' , marginLeft: '10%',fontSize:'25px'}}>เพิ่มเติม: </label>
                        <input
                            style={{width: '50%'}}
                            type="text"
                            id="remark"
                            name="remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                        />
                        <br />
                        <center><button type="submit" class="btn btn-light" style={{width: '10%' , margin:'1%' , padding:'1%' , backgroundColor:'pink' }}>ลงทะเบียน</button></center>
                    </form>
                </div>
            </div>
           
        </div>



  );
}

export default MemberCreate;
