import React, { useState } from "react";
import axios from "axios"; 
import Manubar from "../components/Manubar";
import ManuHot from "../components/Manuhot";
import $ from "jquery"; 



  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: form.serialize(),
        success: (data) => {
            window.alert("ลงทะเบียนเรียบร้อย");
        },
        error: (xhr, status, error) => {
            window.alert("ลงทะเบียนไม่ได้");
        }
    });
  };
const RoomCreate = () => {
    const [roomname , setRoomname] = useState('');
    const [roomtype , setRoomtype] = useState('');
    const [datalist , setDatalist] = useState([]);



    return (
        <div className="row">
        <div className="col-6 col-md-2" style={{ backgroundColor: 'pink' }}>
          <Manubar/>
        </div>
        <div className="col" style={{ backgroundColor: 'white' }}>
        <ManuHot/>
        <div className="col" style={{ backgroundColor: 'white'  }}>
                        <center><h1>สร้างห้อง </h1></center>
                        <div className='mb-3 d-flex justify-content-center'>
                        <form
                        action="https://ikki-cathotel-bangkok.com/php-ikkicathotel/serverroomscreate.php"
                        method="post"
                        onSubmit={handleSubmit}
                    >
                        <input
                            type="text"
                            id="roomname"
                            name="roomname"
                            value={roomname}
                            style={{ width: '500px' , margin:'10px' , padding:'10px' , alignItems:'center'}}
                            onChange={(e) => setRoomname(e.target.value)}
                        />
                         <br />
                            <center><button type="submit">ยืนยัน</button></center>
                         </form>
                        </div>
                    </div>

    
        </div>
        </div>
 

    );
}

export default RoomCreate;
