import React, { useState } from "react";
import $ from "jquery"; 
import Manubar from '../components/Manubar';

function AdminCreate() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [level, setLevel] = useState("");
    const [result, setResult] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success: (data) => {
                window.alert("สำเร็จ")
            },
            error: (xhr, status, error) => {
                window.alert("ไม่สำเร็จ")
            }
        });
      };
    return (
        <div className="row">
            <div className="col-6 col-md-2" style={{ backgroundColor: 'pink' }}>
                <Manubar/>
            </div>
            <div className="col" style={{ backgroundColor: 'white' }}>
            <center><h1>ลงทะเบียน ADMIN </h1></center>
                <div className="container-sm p-4 rounded bg-light shadow d-flex flex-column align-items-center" style={{ marginRight: "10%" , marginLeft: "10%" ,width:"80%" }}>
                    <form
                        action="https://www.ikki-cathotel-bangkok.com/php-ikkicathotel/serveradmin.php"
                        method="post"
                        onSubmit={handleSubmit}
                    >
                        <label htmlFor="username"style={{ width: '100px', padding: '2px', margin: '1px' }}>Username: </label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <br />
                        <label htmlFor="password" style={{ width: '100px', padding: '2px', margin: '1px' }}>Password: </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <br />
                        <label htmlFor="level" style={{ width: '100px', padding: '2px', margin: '1px' }}>Level: </label>
                        <input
                            type="text"
                            id="level"
                            name="level"
                            value={level}
                            onChange={(e) => setLevel(e.target.value)}
                        />
                        <br />
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AdminCreate;
