import React, { useRef, useState, useEffect } from 'react';
import Manubar from '../components/Manubar';
import Axios from 'axios';
import $ from "jquery"; 
import ManuHot from '../components/Manuhot';
import CancelCalendar from '../components/cancelcalendar';

const BookCancel = () => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');

  const [remark, setRemark] = useState('');

  const [phonenumber, setPhonenumber] = useState('');
  const [idnumber, setIdnumber] = useState('');

  const [searchPhoneNumber, setSearchPhoneNumber] = useState('');
  const [searchId, setSearchId] = useState('');

  const [checkindate, setCheckindate] = useState('');
  const [checkoutdate, setCheckoutdate] = useState('');
  const [columnNames, setColumnNames] = useState([]);

  const [bookingid, setBookingID] = useState('');
  const [roomname, setRoomname]= useState('');

  const [bookinglist, setBookinglist] = useState([]);
  const [fullprice, setFullprice]= useState(0);
  const [discount, setDiscount]= useState(0);
  const [priceVat, setPriceVat]= useState(0);
  const [price, setPrice]= useState(0);
  const [otherprice, setOtherprice]= useState(0);
  const [transportationprice, setTransportationprice]= useState(0);
  const [priceperday, setPriceperday]= useState(0);
  const [numberdays, setNumberdays]= useState(0);
  

  const [searchBookingID, setSearchBookingID] = useState('');

  const [days,setDays] = useState(0);
  const [errortextid, setErrorTextID] = useState('');
    
  const [lineid, setLineid] = useState('');
  


  useEffect(() => {
    Axios.get(`https://ikki-cathotel-bangkok.com/php-ikkicathotel/serverbookingdelete.php`)
      .then((response) => {
        setBookinglist(response.data);
 
      })
      .catch((error) => {
        console.error("Error fetching booking data:", error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: form.serialize(),
        success: (data) => {
            window.alert("ลบข้อมูลเรียบร้อย");
        },
        error: (xhr, status, error) => {
            window.alert("ลงข้อมูลไม่ได้");
        }
    });
  };

  const filteredBookingid = bookinglist.filter(booking => {
    // Check if booking and booking.phonenumber are not null before using includes()
    return booking && booking.bookingid && booking.bookingid.includes(searchBookingID);
  });

  const filteredPhonenumber = bookinglist.filter(booking => {
    // Check if booking and booking.phonenumber are not null before using includes()
    return booking && booking.phonenumber && booking.phonenumber.includes(searchPhoneNumber);
  });
  const filteredId = bookinglist.filter(booking => {
    return booking && booking.id && booking.id.toString() === searchId.toString();
    
  });
  

  
  // const filteredId = bookinglist.filter(booking => {
  //   // Check if booking and booking.phonenumber are not null before using includes()
  //   return booking && booking.id && booking.id.includes(searchId);
  // });


  const handleSearchbookingIDChange = (event) => {
    setSearchBookingID(event.target.value);
    setSearchPhoneNumber('');
    setSearchId('');

    // Find the member from the filtered list based on the search phone number
    const foundMember = filteredBookingid.find(member => member.bookingid === event.target.value);

    if (foundMember) {
      setErrorTextID('');
      setId(foundMember.id);
      setCheckindate(foundMember.checkindate);
      setCheckoutdate(foundMember.checkoutdate);
      setBookingID(foundMember.bookingid);
      setRoomname(foundMember.roomname)
      setName(foundMember.name);
      setSurname(foundMember.surname);
      setPhonenumber(foundMember.phonenumber);
      setLineid(foundMember.lineid);
      setIdnumber(foundMember.idnumber);
      setRemark(foundMember.remark);
      setPriceperday(foundMember.priceperday);
      setDays(foundMember.days);
      setTransportationprice(foundMember.transportationprice);
      setOtherprice(foundMember.otherprice);
      setDiscount(foundMember.discount);
      setPriceVat(foundMember.priceVat);
   
    } else {
      setErrorTextID('booking not found');
      setId('');
      setBookingID('');
      setName('');
      setSurname('');
      setPhonenumber('');
      setIdnumber('');
      setLineid('');
      setCheckindate('');
      setCheckoutdate('');
      setRoomname('');
    }
  };

  
  const handleSearchPhonenumberChange = (event) => {
    setSearchBookingID('');
    setSearchPhoneNumber(event.target.value);
    setSearchId('');
    const foundMember = filteredPhonenumber.find(member => member.phonenumber === event.target.value);
    console.log(foundMember);
    if (foundMember) {
      setErrorTextID('');
      setId(foundMember.id);
      setBookingID(foundMember.bookingid);
      setName(foundMember.name);
      setSurname(foundMember.surname);
      setPhonenumber(foundMember.phonenumber);
      setIdnumber(foundMember.idnumber);
      setLineid(foundMember.lineid);
      setCheckindate(foundMember.checkindate);
      setCheckoutdate(foundMember.checkoutdate);
      setRoomname(foundMember.roomname);
    } else {
      setErrorTextID('booking not found');
      setId('');
      setBookingID('');
      setName('');
      setSurname('');
      setPhonenumber('');
      setIdnumber('');
      setLineid('');
      setCheckindate('');
      setCheckoutdate('');
      setRoomname('');
    }
  };
  useEffect(() => {
    const filteredId = bookinglist.filter(booking => {
      return booking && booking.id && booking.id.toString() === searchId.toString();
    });
    
    if (filteredId.length > 0) {
      const foundBooking = filteredId[0]; // Assuming there's only one booking with the specified ID
      setErrorTextID('');
      setId(foundBooking.id);
      setBookingID(foundBooking.bookingid);
      setName(foundBooking.name);
      setSurname(foundBooking.surname);
      setPhonenumber(foundBooking.phonenumber);
      setIdnumber(foundBooking.idnumber);
      setLineid(foundBooking.lineid);
      setCheckindate(foundBooking.checkindate);
      setCheckoutdate(foundBooking.checkoutdate);
      setRoomname(foundBooking.roomname);
      setSearchBookingID('');
      setSearchPhoneNumber('');
    } else {
      // Handle case when booking with the specified ID is not found
      setErrorTextID('Booking not found');
      setId('');
      setBookingID('');
      setName('');
      setSurname('');
      setPhonenumber('');
      setIdnumber('');
      setLineid('');
      setCheckindate('');
      setCheckoutdate('');
      setRoomname('');
   //   searchBookingID('');
    //  searchPhoneNumber('');
    }
  }, [ searchId]);
  


  const formatDate = (dateString) => {
    if (!dateString) {
        return ''; // Return an empty string if dateString is null or undefined
    }
    const date = new Date(dateString);
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};

  


  return (
    <div className="row">
      <div className="col-6 col-md-2" style={{ backgroundColor: 'pink' }}>
        <Manubar/>
      </div>
        
      <div className="col" style={{ backgroundColor: 'white' ,padding:'10px'}}>
      <ManuHot/>
        <div className='row mb-3'>
        <div className='col text-end'><strong>ค้นหาด้วย BOOKING ID</strong></div>
            <div className='col'style={{width: '90%', padding: '2px', margin: '1px' }}>
              <input
      
                type="text"
                className="form-control"
                id="searchBookingID"
                value={searchBookingID}
                onChange={handleSearchbookingIDChange}
              />
            </div>
        </div>

        <div className='row mb-3'>
        <div className='col text-end'><strong>ค้นหาด้วย PHONE NUMBER</strong></div>
            <div className='col' style={{width: '90%', padding: '2px', margin: '1px' }}>
              <input
                //style={{width: '90%', padding: '2px', margin: '1px' }}
                type="text"
                className="form-control"
                id="searchPhoneNumber"
                value={searchPhoneNumber}
                onChange={handleSearchPhonenumberChange}
              />
            </div>
        </div>        

        <div className='row mb-3'>
          <div className='col text-end'><strong>ค้นหาด้วย ID</strong></div>
          <div className='col' style={{width: '90%', padding: '2px', margin: '1px' }}>
            <input 
              
              type="text"
              className="form-control"
              id="searchId"
              value={searchId}
              onChange={(event) => setSearchId(event.target.value)}
            />
          </div>
      
        </div>

        <div className='col text-end' style={{padding: '2px', margin: '30px' ,color:'red' }}>{errortextid}</div>
       


        <div className="col" style={{ backgroundColor: 'white' }}>
        <div className="container-sm p-4 rounded bg-light shadow d-flex flex-column align-items-center" style={{width: '90%', padding: '2px', margin: '5px' }}>
                <center><h1>ยกเลิกการจอง </h1></center>
                    <form
                        action="https://ikki-cathotel-bangkok.com/php-ikkicathotel/serverbookingdelete.php"
                        method="post"
                        onSubmit={handleSubmit}
                        style={{width: '90%'}}
                    >
                      <label htmlFor="id" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>ID: </label>
                      <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="text"
                            id="id"
                            name="id"
                            value={id}
                            readOnly
          
                        />
                        <br />
                      <label htmlFor="checkindate"style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>วันรับฝาก: </label>
                      <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="text"
                            id="checkindate"
                            name="checkindate"
                            value={checkindate}
                            onChange={(e) => setCheckindate(e.target.value)}
     
                        />
                        <br />
                        <label htmlFor="checkoutdate"style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>วันรับคืน: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="text"
                            id="checkoutdate"
                            name="checkoutdate"
                            value={checkoutdate}
                            onChange={(e) => setCheckoutdate(e.target.value)}
           
                        />
                        <br />
                        <label htmlFor="bookingid" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>BOOKING ID: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="bookingid"
                            id="bookingid"
                            name="bookingid"
                            value={bookingid}
                            onChange={(e) => setBookingID('')}
   
                        />
                        <br />
                        <label htmlFor="roomname" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>ชื่อห้องพัก: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="text"
                            id="roomname"
                            name="roomname"
                            value={roomname}
                            onChange={(e) => setRoomname(e.target.value)}
                        
                        />
                        <br />
                        <label htmlFor="name" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>ชื่อ: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                      
                        />
                        <br />
                        <label htmlFor="surname" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>นามสกุล: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="text"
                            id="surname"
                            name="surname"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                    
                        />
                        <br />
                        <label htmlFor="lineid" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>LINE ID: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="text"
                            id="lineid"
                            name="lineid"
                            value={lineid}
                            onChange={(e) => setLineid(e.target.value)}
                  
                        />
                        <br />
                        <label htmlFor="remark" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>เพิ่มเติม: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="text"
                            id="remark"
                            name="remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                
                        />
                        <br />
                        <label htmlFor="phonenumber" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>เบอร์โทรศัพท์: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="text"
                            id="phonenumber"
                            name="phonenumber"
                            value={phonenumber}
                            onChange={(e) => setPhonenumber(e.target.value)}
                            
                        />
                        <br />
                        <label htmlFor="numberdays" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>จำนวนวัน: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="number"
                            id="numberdays"
                            name="numberdays"
                            value={numberdays}
                            onChange={(e) => setNumberdays(e.target.value)}
                        />
                        <br />
                        <label htmlFor="priceperday" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>ราคาต่อวัน: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="number"
                            id="priceperday"
                            name="priceperday"
                            value={priceperday}
                            onChange={(e) => setPriceperday(e.target.value)}
                        />
                        <br />
                        <label htmlFor="fullprice" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>ราคาที่พัก: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="number"
                            id="fullprice"
                            name="fullprice"
                            value={fullprice}
                            readOnly // Make it read-only
                        />
                        <br />
                        <label htmlFor="transportationprice" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>ค่าเดินทาง: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="number"
                            id="transportationprice"
                            name="transportationprice"
                            value={transportationprice}
                            onChange={(e) => setTransportationprice(e.target.value)}
                        />
                        <br />
                        <label htmlFor="otherprice" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>อื่นๆ: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="number"
                            id="otherprice"
                            name="otherprice"
                            value={otherprice}
                            onChange={(e) => setOtherprice(e.target.value)}
                        />
                        <br />

                        {/* Discount input */}
                        <label htmlFor="discount" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>เปอร์เซนต์ส่วนลด: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="number"
                            id="discount"
                            name="discount"
                            value={discount}
                            onChange={(e) => setDiscount(e.target.value)}
                        />
                        <br />

                        {/* VAT input */}
                        <label htmlFor="priceVat" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>VAT: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="number"
                            id="priceVat"
                            name="priceVat"
                            value={priceVat}
                            readOnly // Make it read-only
                        />
                        <br />

                        {/* Total input */}
                        <label htmlFor="price" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>ราคาก่อน VAT: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="number"
                            id="price"
                            name="price"
                            value={price}
                            readOnly // Make it read-only
                        />
                        <br />
                        <label htmlFor="totalprice" style={{ width: '25%', padding: '2px', margin: '1px' ,fontSize:'25px' }}>ราคาหลัง VAT: </label>
                        <input style={{ width: '70%', padding: '2px', margin: '1px' ,fontSize:'25px'  }}
                            type="number"
                            id="totalprice"
                            name="totalprice"
                            value={(parseFloat(price) + parseFloat(priceVat)).toFixed(2)} // Convert strings to numbers and sum them up
                            readOnly
                        />

                        <br />

                     
                       <center><button className='btn btn-primary' type="submit" style={{   borderRadius: '10px', margin: '10px', padding: '10px'  }}>ยกเลิกการจอง</button></center> 
                    </form>
                    <CancelCalendar  checkindate={checkindate} checkoutdate={checkoutdate} roomname={roomname} bookingid={bookingid}/>
                </div>
              
            </div>
            
         
      </div>
      
    </div>
  );
}

export default BookCancel;
