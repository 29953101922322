import React, { useState, useEffect } from "react";
import Manubar from '../components/Manubar';
import ManuHot from "../components/Manuhot";
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CalendarShow from "../components/calendarshow";

const Dashboard = () => {

  const [datalist, setDatalist] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get(`https://www.ikki-cathotel-bangkok.com/php-ikkicathotel/serverbooking.php`)
      .then((response) => {
        setDatalist(response.data);
        console.log(datalist)
      })
      .catch((error) => {
        console.error("Error fetching booking data:", error);
      });
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) {
        return ''; // Return an empty string if dateString is null or undefined
    }
    const date = new Date(dateString);
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const currentDate = new Date();
    if (date > currentDate) {
        return 'Check-in coming soon';
    }
    return formattedDate;
  };
  const handleBookingManagementClick = () => {
    navigate('/booking/manament');
  };

  const handleMemberCreateClick = () => {
    navigate('/member/create');
  };

  const handleInvoicePrintClick = () => {
    navigate('/invoice/print');
  };
  const handleTohome = () => {
    navigate('/dashboard');
  };



  return (


    <div className="row">
      <div className="col-6 col-md-2" style={{ backgroundColor: 'pink' }}>
        <Manubar />
      </div>
      <div className="col"  style={{ width: "100%" ,backgroundColor:"white" }}>
        <div className="row-2" style={{ backgroundColor: 'white' , display:'flex'  ,width:'100%',justifyContent:'space-around'}}>

          <button type="button"  className='btn btn-light' onClick={handleMemberCreateClick} style={{ margin: "5%" }}> 
            <img src="https://ikki-cathotel-bangkok.com/images/but001.jpg"   alt="Booking" style={{ width: "100%" }} />
          </button>

          <button type="button"  className='btn btn-light' onClick={handleBookingManagementClick} style={{ margin: "5%" }}> 
            <img src="https://ikki-cathotel-bangkok.com/images/but002.jpg" alt="Booking" style={{ width: "100%" }} />
          </button>
        
        </div>

        <div className="row-2" style={{ backgroundColor: 'white' , display:'flex'  ,width:'100%',justifyContent:'space-around'}}>

          <button type="button"  className='btn btn-light' onClick={handleInvoicePrintClick} style={{ margin: "5%" }}> 
            <img src="https://ikki-cathotel-bangkok.com/images/but003.jpg"   alt="Booking" style={{ width: "100%" }} />
          </button>

          <button type="button"  className='btn btn-light' onClick={handleTohome} style={{ margin: "5%" }}> 
            <img src="https://ikki-cathotel-bangkok.com/images/but004.jpg" alt="Booking" style={{ width: "100%" }} />
          </button>
        
        </div>
        <CalendarShow/>

      


      </div>
      
    </div>


       


  );
}

export default Dashboard;
