import React, { useState } from "react";
import $ from "jquery";

const BookCalendar = ({checkindate , checkoutdate , roomname , bookingid } ) => {
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formSubmitted) { // Check if form has already been submitted
            setFormSubmitted(true); // Set formSubmitted to true to indicate form submission
            const form = $(e.target);
            $.ajax({
                type: "POST",
                url: form.attr("action"),
                data: form.serialize(),
                success: (data) => {
                    window.alert("ทำการจองสำเร็จ");
                },
                error: (xhr, status, error) => {
                    window.alert("ทำการจองไม่ได้");
                }
            });
        }
    };

    return (
        <div className="row">
            <form
                action="https://www.ikki-cathotel-bangkok.com/php-ikkicathotel/serverroomscontrol.php"
                method="post"
                onSubmit={handleSubmit}
            >
                <input type="hidden" id="checkindate" name="checkindate" value={checkindate} />
                <input type="hidden" id="checkoutdate" name="checkoutdate" value={checkoutdate} />
                <input type="hidden" id="roomname" name="roomname" value={roomname} />
                <input type="hidden" id="bookingid" name="bookingid" value={bookingid} />
                <br />
                <div style={{ textAlign: 'center' }}>
                    <button type="submit" disabled={formSubmitted}>Book On Calendar</button>
                    
                </div>
            </form>    
        </div>
    )
}

export default BookCalendar;
