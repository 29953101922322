// App.js
import { createRoot } from 'react-dom/client'; 
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminCreate from "./pages/appadmincreate"
import IkkiHomePage from './pages/Ikkihomepage';
import LoginPage from './pages/loginpage';
import BookingGet from './pages/bookingGet';
import Dashboard from './pages/dashboard';
import BookingCancel from './pages/bookCancel';
import RoomCreate from './pages/roomCreate';
import RoomDelete from './pages/roomDelete';
import MemberCreate from './pages/memberCreate';
import MemberDelete from './pages/memberDelete';
import InvoicePrint from './pages/invoicePrint';
import BookingManagement from './pages/bookManagement';
import BackupIkkiHomePage from './pages/backupikkihome';


function App() {


  return (
    <BrowserRouter>
      
      <Routes>
      
          <Route path="test" element={<BackupIkkiHomePage />} />
          <Route path="" element={<IkkiHomePage />} />
          <Route path="/" element = {<IkkiHomePage/>}/>

          <Route path="dashboard" element = {<Dashboard/>}/>
          <Route path="admin" element = {<AdminCreate/>}/>

          <Route path="pagelogin" element = {<LoginPage/>}/>
          <Route path="booking/get" element = {<BookingGet/>}/>
          <Route path="booking/manament" element = {<BookingManagement/>}/>
          <Route path="booking/delete" element = {<BookingCancel/>}/>

          <Route path="room/create" element={<RoomCreate />} />
          <Route path="room/delete" element={<RoomDelete />} />

          <Route path="member/create" element={<MemberCreate />} />
          <Route path="member/delete" element={<MemberDelete/>}/> 
          
          <Route path="invoice/print" element = {<InvoicePrint/>}/>






        <Route  />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
