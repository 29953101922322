import { useState, useEffect } from "react";
import $ from "jquery";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion"

function LoginPage() {
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [result, setResult] = useState("");

    useEffect(() => {
        if (result.length >40) {  
            navigate('/dashboard');
        }
    }, [result, navigate]);

    const handleChangeUsername = (e) => {
        setUsername(e.target.value);
    };

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
            error() {
                // Handle error
                console.error("Error occurred during login.");
            }
        });
    };

    return (

            <div className="container-fluid bg-dark d-flex justify-content-center align-items-center" style={{padding:"10%" , backgroundColor:"white"}}>
                <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
        >
                <div className="container-sm p-4 rounded bg-light shadow d-flex flex-column align-items-center" style={{ margin: "5px" }}>
                    <img src="https://ikki-cathotel-bangkok.com/logo192.png" alt="Logo" style={{ width: "200px", marginBottom: "10px" }} />
                    <h2 className="text-center mb-4">Login</h2>
                    <form
                        action="https://ikki-cathotel-bangkok.com/php-ikkicathotel/serverloginapp.php"
                        method="post"
                        onSubmit={handleSubmit}
                    >
                        <div className="mb-3 d-flex" style={{ width: "50%" }}>
                            <label htmlFor="username" style={{ margin: "5px" }}>Username: </label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                value={username}
                                onChange={handleChangeUsername}
                            />
                        </div>
                        <div className="mb-3 d-flex" style={{ width: "100%" }}>
                            <label htmlFor="password" style={{ margin: "5px" }}>Password : </label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                onChange={handleChangePassword}
                            />
                            <br />
                        </div>
                        <center><button type="submit">Submit</button></center>
                    </form>
                </div>
                </motion.div>
            </div>
        
    );
}

export default LoginPage;
