import { Button } from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';


function Manubar() {

  const navigate = useNavigate();


  const handleBookingManagementClick = () => {
    navigate('/booking/manament');
  };
  const handleBookingGetClick = () => {
    navigate('/booking/get');
  };
  const handleBookingEditClick = () => {
    navigate('/booking/edit');
  };
  const handleBookingDeleteClick = () => {
    navigate('/booking/delete');
  };
  const handleRoomCreateClick = () => {
    navigate('/room/create');
  };
  const handleRoomDeleteClick = () => {
    navigate('/room/delete');
  };

  const handleMemberCreateClick = () => {
    navigate('/member/create');
  };

  const handleMemberDeleteClick = () => {
    navigate('/member/delete');
  };
  const handleAddAdminClick = () => {
    navigate('/admin');
  };
  const handleInvoicePrintClick = () => {
    navigate('/invoice/print');
  };
  const handleTohome = () => {
    navigate('/dashboard');
  };
  const 
  handleLogout = () => {
    navigate('/');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '0px', width: '100%' , minHeight: '800px',textAlign:'center'}}>

      <div style={{ color: 'white', margin: '10px' ,backgroundColor: 'pink'}}>
      <button type="button" class="btn btn-outline-light">
            <img src="https://www.ikki-cathotel-bangkok.com/logo.png"  alt="logo cathotel" width="100" height="auto" backgroundColor="pink" onClick={handleTohome}/>
      </button>
      </div>


      <div style={{ backgroundColor: 'pink', flexDirection: 'column', margin: '10px', width: '100%' ,textAlign:'center'}}>


    


      <div className='box d-flex flex-column align-items-center' style={{ color: 'black', margin: '10px', marginTop: '20px' }}> <h3>จัดการสมาชิก </h3> 
          <div > {/* Aligns items to the end (right) */}
            <button type="button" className="btn btn-outline-light" style={{ color: 'black',width: '200px',  padding: '2px',  margin: '1px', fontSize: '20px'}}  onClick={handleMemberCreateClick}>เพิ่มสมาชิก</button>
          </div>
   
          <div > {/* Aligns items to the end (right) */}
            <button type="button" className="btn btn-outline-light" style={{color: 'black', width: '200px',  padding: '2px',  margin: '1px', fontSize: '20px'}}  onClick={handleMemberDeleteClick}>ลบสมาชิก</button>
          </div>
          <div > {/* Aligns items to the end (right) */}
            <button type="button" className="btn btn-outline-light" style={{ color: 'black',width: '200px',  padding: '2px',  margin: '1px', fontSize: '20px'}}  onClick={handleAddAdminClick}>เพิ่ม Admin</button>
          </div>
      </div>

      <div className='box d-flex flex-column align-items-center' style={{ color: 'black', marginTop: '20px' ,backgroundColor: 'pink'}}> <h3>จัดการการจอง </h3> 
        <div>
          <button type="button" className="btn btn-outline-light" style={{color: 'black', width: '200px', padding: '2px', margin: '1px', fontSize: '20px' }} onClick={handleBookingGetClick}>ค้นหาการจอง</button>
        </div>
        <div>
          <button type="button" className="btn btn-outline-light" style={{color: 'black', width: '200px', padding: '2px', margin: '1px', fontSize: '20px' }} onClick={handleBookingManagementClick}>จองที่พัก</button>
        </div>
        <div>
          <button type="button" className="btn btn-outline-light" style={{ color: 'black',width: '200px', padding: '2px', margin: '1px' , fontSize: '20px'}} onClick={handleBookingDeleteClick}>ยกเลิกการจอง</button>
        </div>
      </div>

      
      <div className='box d-flex flex-column align-items-center' style={{ color: 'black', margin: '10px', marginTop: '20px' }}> <h3>ใบเสร็จ/ใบกำกับภาษี</h3>  
              
        <div > {/* Aligns items to the end (right) */}
              <button type="button" className="btn btn-outline-light" style={{color: 'black', width: '200px',  padding: '2px',  margin: '1px', fontSize: '20px'}}  onClick={handleInvoicePrintClick}>พิมพ์ใบกำกับภาษี</button>
        </div>

      </div>

      <div className='box d-flex flex-column align-items-center' style={{ color: 'black', margin: '10px', marginTop: '20px' }}> <h3>จัดการห้องพัก </h3> 
          <div > {/* Aligns items to the end (right) */}
            <button type="button" className="btn btn-outline-light" style={{ color: 'black',width: '200px',  padding: '2px',  margin: '1px', fontSize: '20px'}}  onClick={handleRoomCreateClick}>เพิ่มห้องพัก</button>
          </div>
          <div > {/* Aligns items to the end (right) */}
            <button type="button" className="btn btn-outline-light" style={{ color: 'black',width: '200px',  padding: '2px',  margin: '1px', fontSize: '20px'}}  onClick={handleRoomDeleteClick}>ลบห้องพัก</button>
          </div>
      </div>

      


      <div className='box d-flex flex-column align-items-center' style={{ color: 'black', margin: '10px', marginTop: '20px' }}> <h3>ออกจากระบบ</h3>  
              
              <div >
                    <button type="button" className="btn btn-outline-light" style={{color: 'black', width: '200px',  padding: '2px',  margin: '1px', fontSize: '20px'}}  onClick={handleLogout}>LOG OUT</button>
              </div>
      
        </div>

      </div>
      


    </div>
    
  );
}

export default Manubar;

