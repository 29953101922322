import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from 'react-dom/client' instead of 'react-dom'
import './index.css';
import App from './App'; // Assuming App.js or App.jsx contains the App component
import reportWebVitals from './reportWebVitals';

// Use createRoot from react-dom/client
const root = createRoot(document.getElementById('root')); 
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
