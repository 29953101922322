import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Stack } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Manubar from '../components/Manubar';
import ManuHot from '../components/Manuhot';
import Bookingcancel from '../components/bookingcancel';
import UpdateStatus from '../components/updatestatus';
function BookingGet() {
    const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');

  const [remark, setRemark] = useState('');

  const [phonenumber, setPhonenumber] = useState('');
  const [idnumber, setIdnumber] = useState('');

  const [searchPhoneNumber, setSearchPhoneNumber] = useState('');
  const [searchId, setSearchId] = useState('');

  const [checkindate, setCheckindate] = useState('');
  const [checkoutdate, setCheckoutdate] = useState('');
  const [columnNames, setColumnNames] = useState([]);

  const [bookingid, setBookingID] = useState('');
  const [roomname, setRoomname]= useState('');

  const [bookinglist, setBookinglist] = useState([]);
  const [fullprice, setFullprice]= useState(0);
  const [discount, setDiscount]= useState(0);
  const [priceVat, setPriceVat]= useState(0);
  const [price, setPrice]= useState(0);
  const [otherprice, setOtherprice]= useState(0);
  const [transportationprice, setTransportationprice]= useState(0);
  const [priceperday, setPriceperday]= useState(0);
  const [numberdays, setNumberdays]= useState(0);
  

  const [searchBookingID, setSearchBookingID] = useState('');

  const [days,setDays] = useState(0);
  const [errortextid, setErrorTextID] = useState('');
    
  const [lineid, setLineid] = useState('');

    const datein = new Date(checkindate);
    const dayin = datein.getDate().toString().padStart(2, '0'); 
    const monthin = (datein.getMonth() + 1).toString().padStart(2, '0'); 
    const yearin = datein.getFullYear().toString();
    const dateout = new Date(checkoutdate);
    const dayout = dateout.getDate().toString().padStart(2, '0'); 
    const monthout = (dateout.getMonth() + 1).toString().padStart(2, '0'); 
    const yearout = dateout.getFullYear().toString();
    const checkinDateNumber = `${yearin}${monthin}${dayin}`;
    const checkoutDateNumber = `${yearout}${monthout}${dayout}`;

    useEffect(() => {
      setBookingID(`${roomname}${phonenumber}${checkinDateNumber}${checkoutDateNumber}`);
    
    }, [roomname, phonenumber, checkindate,checkoutdate]);

    useEffect(() => {
      Axios.get(`https://ikki-cathotel-bangkok.com/php-ikkicathotel/serverbookingdelete.php`)
        .then((response) => {
          setBookinglist(response.data);
   
        })
        .catch((error) => {
          console.error("Error fetching booking data:", error);
        });
    }, []);

      const filteredPhonenumber = bookinglist.filter(booking => {
        // Check if booking and booking.phonenumber are not null before using includes()
        return booking && booking.phonenumber && booking.phonenumber.includes(searchPhoneNumber);
      });

  const handleSearchPhonenumberChange = (event) => {
    setSearchPhoneNumber(event.target.value);
  
    const foundMember = filteredPhonenumber.find(member => member.phonenumber === event.target.value);
    if (foundMember) {
      setErrorTextID('');
      setId(foundMember.id);
      setBookingID(foundMember.bookingid);
      setName(foundMember.name);
      setSurname(foundMember.surname);
      setPhonenumber(foundMember.phonenumber);
      setIdnumber(foundMember.idnumber);
      setLineid(foundMember.lineid);
      setCheckindate(foundMember.checkindate);
      setCheckoutdate(foundMember.checkoutdate);
      setRoomname(foundMember.roomname);
    } else {
      setErrorTextID('booking not found');
      setId('');
      setBookingID('');
      setName('');
      setSurname('');
      setPhonenumber('');
      setIdnumber('');
      setLineid('');
      setCheckindate('');
      setCheckoutdate('');
      setRoomname('');
    }
  };

    const formatDate = (dateString) => {
      if (!dateString) {
          return ''; // Return an empty string if dateString is null or undefined
      }
      const date = new Date(dateString);
      const options = { month: 'short', day: '2-digit', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
  };
  
    return (
        <div className="row">
            <div className="col-6 col-md-2" style={{ backgroundColor: 'pink' }}>
                <Manubar/>
            </div>
            <div className="col" style={{ backgroundColor: 'white' }}>
            <ManuHot/>
              <br/>
              <div className='row mb-3'>
                <div className='col text-end'><strong>ค้นหาด้วย PHONE NUMBER</strong></div>
                    <div className='col' style={{width: '90%', padding: '2px', margin: '1px' }}>
                      <input
                        //style={{width: '90%', padding: '2px', margin: '1px' }}
                        type="text"
                        className="form-control"
                        id="searchPhoneNumber"
                        value={searchPhoneNumber}
                        onChange={handleSearchPhonenumberChange}
                      />
                    </div>
                </div>      
              <table className="table table-striped" style={{textAlign:'center'}}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Booking ID</th>
                    <th>เบอร์โทรศัพท์</th>
                    <th>ชื่อ</th>
                    <th>นามสกุล</th>
                    <th>LineID</th>
                    <th>เบอร์โทรศัพท์</th>
                    <th>เลขประจำตัว</th>
                    <th>เพิ่มเติม</th>
                    <th>Check in</th>
                    <th>Check out</th>
                    <th>วันที่ทำการจอง</th>
                    <th>ห้อง</th>
                    <th>สถานะ</th>
                    <th>Cancel</th>
                  </tr>
                </thead>
                <tbody >
                {bookinglist.map((val, key) => {
                  if (val.phonenumber === searchPhoneNumber) {
                    return (
                      <tr key={key}>
                         <td>{val.id}</td>
                        <td>{val.bookingid}</td>
                        <td>{val.phonenumber}</td>
                        <td>{val.name}</td>
                        <td>{val.surname}</td>
                        <td>{val.lineid}</td>
                        <td>{val.phonenumber}</td>
                        <td>{val.idnumber}</td>
                        <td>{val.remark}</td>
                        <td>{formatDate(val.checkindate)}</td>
                        <td>{formatDate(val.checkoutdate)}</td>
                        <td>{formatDate(val.bookingdate)}</td>
                        <td>{val.roomname}</td>
                       
                        <td><UpdateStatus id={val.id} initialStatus={val.status}/></td>

                        <td>
                          <Bookingcancel id={val.id} roomname={val.roomname} checkindate={val.checkindate} checkoutdate={val.checkoutdate} />
                        </td>
                      </tr>
                    );
                  } else {
                    // return (
                    //   <tr key={key}>
                    //      <td>{val.id}</td>
                    //     <td>{val.bookingid}</td>
                    //     <td>{val.phonenumber}</td>
                    //     <td>{val.name}</td>
                    //     <td>{val.surname}</td>
                    //     <td>{val.lineid}</td>
                    //     <td>{val.phonenumber}</td>
                    //     <td>{val.idnumber}</td>
                    //     <td>{val.remark}</td>
                    //     <td>{formatDate(val.checkindate)}</td>
                    //     <td>{formatDate(val.checkoutdate)}</td>
                    //     <td>{formatDate(val.bookingdate)}</td>
                    //     <td>{val.roomname}</td>
                    //     <td>
                    //       <Bookingcancel id={val.id} roomname={val.roomname} checkindate={val.checkindate} checkoutdate={val.checkoutdate} />
                    //     </td>{/* Render table cells */}
                    //   </tr>
                    // );
                  }
                })}
                {/* {bookinglist.map((val, key) => {
                  if (val.phonenumber === searchPhoneNumber) {
                    return (
                      <tr key={key}>
                        <td>{val.id}</td>
                        <td>{val.bookingid}</td>
                        <td>{val.phonenumber}</td>
                        <td>{val.name}</td>
                        <td>{val.surname}</td>
                        <td>{val.lineid}</td>
                        <td>{val.phonenumber}</td>
                        <td>{val.idnumber}</td>
                        <td>{val.remark}</td>
                        <td>{formatDate(val.checkindate)}</td>
                        <td>{formatDate(val.checkoutdate)}</td>
                        <td>{formatDate(val.bookingdate)}</td>
                        <td>{val.roomname}</td>
                        <td>
                          <Bookingcancel id={val.id} roomname={val.roomname} checkindate={val.checkindate} checkoutdate={val.checkoutdate} />
                        </td>
                      </tr>
                    );
                  } else {
                    {bookinglist.map((val, key) => {
                     {
                        return (
                          <tr key={key}>
                            <td>{val.id}</td>
                            <td>{val.bookingid}</td>
                            <td>{val.phonenumber}</td>
                            <td>{val.name}</td>
                            <td>{val.surname}</td>
                            <td>{val.lineid}</td>
                            <td>{val.phonenumber}</td>
                            <td>{val.idnumber}</td>
                            <td>{val.remark}</td>
                            <td>{formatDate(val.checkindate)}</td>
                            <td>{formatDate(val.checkoutdate)}</td>
                            <td>{formatDate(val.bookingdate)}</td>
                            <td>{val.roomname}</td>
                            <td>
                              <Bookingcancel id={val.id} roomname={val.roomname} checkindate={val.checkindate} checkoutdate={val.checkoutdate} />
                            </td>
                          </tr>
                        )
                      }
                    }
                  
                  }
                })} */}

                </tbody>
              </table>
              
            </div>
          
                    
            </div>
  
    );
} 

export default BookingGet;
