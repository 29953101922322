import React, { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import Axios from 'axios';
import Manubar from '../components/Manubar';
import ManuHot from '../components/Manuhot';
const InvoicePrint = () => {
  const contentToPrint = useRef(null);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');

  const [checkindate, setCheckindate] = useState('');
  const [checkoutdate, setCheckoutdate] = useState('');
  const [fullprice, setFullprice] = useState(0);
  const [rentalprice, setRentalprice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [priceVat, setPriceVat] = useState(0);
  const [price, setPrice] = useState(0);
  const [priceperday, setPriceperday] = useState(0);
  const [days, setDays] = useState(0);

  const [remark, setRemark] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [bookinglist, setBookinglist] = useState([]);
  const [memberlist, setMemberlist] = useState([]);
  const [transportationprice, setTransportationprice] = useState(0);
  const [otherprice, setOtherprice] = useState(0);
  const [roomname, setRoomName] = useState('');
  const [bookingid, setBookingid] = useState('');
  const [address, setAddress] = useState('');
  const [idnumber, setIdnumber] = useState('');
  const [bookingdate , setBookingdate]= useState('');
  const [totalprice , setTotalPrice]= useState('');





  const [searchBookingID, setSearchBookingID] = useState('');

 


  useEffect(() => {
    Axios.get(`https://ikki-cathotel-bangkok.com/php-ikkicathotel/serverbookingcreate.php`)
      .then((response) => {
        setBookinglist(response.data);
        if (response.data.length > 0) {
          const lastBookingId = response.data[response.data.length - 1].id;
          setId(lastBookingId);
        }
      })
      .catch((error) => {
        console.error("Error fetching booking data:", error);
      });
  }, []);







const filteredBookingid = bookinglist.filter(booking => {
  // Check if booking and booking.phonenumber are not null before using includes()
  return booking && booking.bookingid && booking.bookingid.includes(searchBookingID);
});


  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setId(selectedId);

  
    const selectedBooking = bookinglist.find(booking => booking.id === parseInt(selectedId));
    if (selectedBooking) {
      setBookingid(selectedBooking.bookingid);
      setId(selectedBooking.id)
      setCheckindate(selectedBooking.checkindate);
      setCheckoutdate(selectedBooking.checkoutdate);
      setFullprice(selectedBooking.fullprice);
      setDiscount(selectedBooking.discount);
      setPrice(selectedBooking.price);
      setPriceVat(selectedBooking.priceVat);
      setBookingdate(selectedBooking.bookingdate);
      setDays(selectedBooking.days)
      setRoomName(selectedBooking.roomname);
      setRemark(selectedBooking.remark);
      setName(selectedBooking.name);
      setSurname(selectedBooking.surname);
      setPhonenumber(selectedBooking.phonenumber);
      setIdnumber(selectedBooking.idnumber);
      setTransportationprice(selectedBooking.transportationprice);
      setOtherprice(selectedBooking.otherprice);
      setAddress(selectedBooking.address);
      setPriceperday(selectedBooking.priceperday);
      setRentalprice(selectedBooking.rentalprice);

    }
  };

  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  useEffect(() => {
        
    setFullprice(days*priceperday);
    }, [ days , priceperday ]);

    useEffect(() => {
        setTotalPrice( (fullprice + +transportationprice + +otherprice)*(1-discount/100));
    }, [fullprice , transportationprice , otherprice , discount]);

    useEffect(() => {
        setPrice((totalprice)/1.07);
    }, [totalprice]);

    useEffect(() => {
        setPriceVat((price)*0.07);
    }, [price]);

    return (
      <div className="row">
      <div className="col-6 col-md-2" style={{ backgroundColor: 'pink' }}>
        <Manubar/>
      </div>
      <div className="col" style={{ backgroundColor: 'white',  minHeight: '800px' }}>
      <ManuHot/>
      <div className="container mt-5">
        <h1>พิมพ์ใบเสร็จรับเงิน/ใบกำกับภาษี</h1>
      <select className="form-select mb-3" onChange={handleSelectChange} value={id}>
        <option value="">Select Booking ID</option>
        {bookinglist.map((val, key) => (
          <option key={key} value={val.id}>
            Booking ID: {val.id}
          </option>
        ))}
      </select>

      {id && (
        <div ref={contentToPrint} className='invoice' style={{margin:'5%',width:'90%'}}>
          <hr/>
          <div classname='row' style={{display:'flex'}}>
            <img src="https://www.ikki-cathotel-bangkok.com/logo.png"  alt="logo cathotel" width="100px" height="120px" />
            <div>
              <p class="fw-bold" style={{fontSize:'26px' , marginLeft:'35px'}}>
                บริษัท พูนศรีชำนาญการ พร็อพเพอร์ตี้ จำกัด (สำนักงานใหญ่)<br/>
                
                <p className="fw-normal" style={{fontSize:'12px' }}>สำนักงานใหญ่ : 17/222 ซอยประชาชื่น 14 (ม.การไฟฟ้าส่วนภูมิภาค) แขวงทุ่งสองห้อง เขตหลักสี่ กทม. 10210</p>
                <p className="fw-normal" style={{fontSize:'12px'  }}>โทร. 083-555-9935 เลขประจำตัวผู้เสียภาษี 0105561211535  E-mail: i_force@hotmail.com </p>
                            
              </p>
            </div>
   
            
          </div>
          <br/>
          <div className="border border-3 rounded" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'  }}>
              <div className="fw-bold" style={{fontSize: '20px', padding: '12px', margin: '0px' }}>
                ใบเสร็จรับเงิน<br/> 
                RECEIPT
              </div>
          </div>

            <br/> 
          <br/>
          <div className='row' style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className='col-6'>
              <div style={{display:'flex',width:'100%'}}>
              <div style={{margin:'2px' ,padding:'0%'}}>นามลูกค้า : </div>
                <div style={{margin:'2px'}}> {name}   {surname}    </div>    
              </div>
              <div style={{display:'flex'}}>
                <div style={{margin:'2px' ,padding:'0%', width:'25%'}}>ที่อยู่ : </div>
                <div style={{margin:'2px'}}> {address}  </div>    
              </div>
              <div style={{display:'flex'}}>
              <div style={{margin:'2px' ,padding:'0%'}}>เลขผู้เสียภาษี : </div>
                <div style={{margin:'2px'}}> {idnumber}  </div>    
              </div>
              <div style={{display:'flex'}}>
              <div style={{margin:'2px' ,padding:'0%'}}>โทร : </div>
                <div style={{margin:'2px'}}> {phonenumber}  </div>    
              </div>
            </div>
            <div className='col-6'>
              <div>เลขที่: {id}</div>
              <div>รหัสการจอง:{bookingid}</div>
              <div>วันที่  {new Date(bookingdate).toLocaleDateString()}</div>
            </div>
            
          </div>

            
          

          <table className="table">
            <thead>
              <tr>
                <th scope="col">ลำดับ</th>
                <th scope="col">รายละเอียด</th>
                <th scope="col">จำนวน</th>
                <th scope="col">หน่วย</th>
                <th scope="col">ราคา/หน่วย</th>
                <th scope="col">จำนวนเงิน</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>ห้อง {roomname} เข้าพัก:{new Date(checkindate).toLocaleDateString()} ออกจากที่พัก:{new Date(checkoutdate).toLocaleDateString()}</td>
                <td>{days}</td>
                <td>วัน</td>
                <td>{priceperday}</td>
                <td>{parseFloat(fullprice).toFixed(2)}</td>
              </tr>
              <tr>
                <td>2</td>
                <td>ค่าเดินทาง</td>
                <td>1</td>
                <td>รอบ</td>
                <td>{transportationprice}</td>
                <td>{parseFloat(transportationprice).toFixed(2)}</td>
              </tr>
              <tr>
                <td>3</td>
                <td>ค่าบริการอื่นๆ </td>
                <td>1</td>
                <td>ครั้ง</td>
                <td>{otherprice}</td>
                <td>{parseFloat(otherprice).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
          <div className="col" style={{display:"flex"}}>
            <div className="col-sm">หมายเหตุ: {remark}</div>
    
            <div className="col-sm">
              <div className='container-sum'>
                <div className='row'>
                  <div className='col text-end'><strong>ราคาเต็ม</strong></div>
                  <div className='col text-start'>{parseFloat((fullprice+ +otherprice+ +transportationprice)).toFixed(2)} บาท</div>
                </div>
              </div>
              <div className='container-sum'>
                <div className='row'>
                  <div className='col text-end'><strong>ส่วนลด</strong></div>
                  <div className='col text-start'>{discount} %</div>
                </div>
              </div>
              <div className='container-sum'>
                <div className='row'>
                  <div className='col text-end'><strong>VAT 7%</strong></div>
                  <div className='col text-start'>{parseFloat(priceVat).toFixed(2)} บาท</div>
                </div>
              </div>

            
              <div className='container-sum'>
                <div className='row'>
                  <div className='col text-end'><strong>จำนวนก่อนหัก ณ ที่จ่าย</strong></div>
                  <div className='col text-start'>{parseFloat(price).toFixed(2)}  บาท</div>
                </div>
              </div><br/>
              <div className='container-sum'>
                <div className='row'>
                  <div className='col text-end'><strong>จำนวนรวมหักภาษี ณ ที่จ่าย</strong></div>
                  <div className='col text-start'>{parseFloat(price+priceVat).toFixed(2)}  บาท</div>
                </div>
              </div><br/>
            </div>
          </div>
         
          

          <div className="column" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center'  }}>
              <div className="border border-3 rounded fw-bold" style={{fontSize: '16px', margin: '0px' , width:'35%' ,height:'150px'   }}><br/><br/>
                ผู้จัดทำ......................... <br/><br/>
                ลงวันที่.........................
              </div>
              <div className="border border-3 rounded fw-bold" style={{fontSize: '16px', margin: '0px' , width:'35%' ,height:'150px'   }}><br/><br/>
                ผู้ตรวจสอบ...................... <br/><br/>
                ลงวันที่..........................
              </div>
              <div className="border border-3 rounded fw-bold" style={{fontSize: '12px', margin: '0px' , width:'35%' ,height:'150px'   }}><br/>
              <br/>บริษัท พูนศรีชำนาญการ พร็อพเพอร์ตี้ จำกัด<br/><br/><br/>
              ....................................................<br/>
                ผู้มีอำนาจลงนาม
              </div>
          </div>
          
          <hr/>

 
      
        </div>
      )}


        <div className='col-6 text-end'>
          <button className='btn btn-primary' onClick={() => handlePrint(null, () => contentToPrint.current)}>Print</button>
        </div>
    </div>
  
      </div>
      </div>
    ); 


}

export default InvoicePrint;
