import React, { useState, useEffect } from "react";
import Axios from 'axios'; 
import Manubar from '../components/Manubar';
import $ from "jquery"; 
import ManuHot from "../components/Manuhot";

const RoomDelete = () => {
    const [roomname, setRoomname] = useState([]);
    const [roomnamelist, setRoomnamelist] = useState([]);
    const [selectedroomname, setSelectedroomname] = useState('');
    const [columnnames, setColumnNames] = useState('');
    useEffect(() => {
        Axios.get(`https://www.ikki-cathotel-bangkok.com/php-ikkicathotel/serverroomsdelete.php`)
            .then(response => {             
                    setRoomnamelist(response.data);
            })
            .catch(error => {
                console.error('Error fetching column names:', error);
            });
            console.log("Roomnamelist:", roomnamelist);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success: (data) => {
                window.alert("ลบห้องเรียบร้อย");
            },
            error: (xhr, status, error) => {
                window.alert("ลบห้องไม่ได้");
            }
        });
    };

    return (
        <div className="row">
            <div className="col-6 col-md-2" style={{ backgroundColor: 'pink' }}>
                <Manubar/>
            </div>
            <div className="col" style={{ backgroundColor: 'white' }}>
            <ManuHot/>
                <div className="container mt-5">
                    <div className="col" style={{ backgroundColor: 'white' }}>
                        <center><h1>ลบห้อง </h1></center>
                        <div className='mb-3 d-flex justify-content-center'>
                            <form
                                action="https://www.ikki-cathotel-bangkok.com/php-ikkicathotel/serverroomsdelete.php"
                                method="post"
                                onSubmit={handleSubmit}
                            >
    
                                <select
                                    id="roomname"
                                    name="roomname"
                                    value={roomname}
                                    onChange={(event) => setRoomname(event.target.value)}
                                    style={{ width: '500px' , margin:'10px' , padding:'10px' , alignItems:'center'}}
                                >
                                    <option value="roomname" style={{margin:'10px' , padding:'10px'}}>เลือกห้องที่ต้องการ</option>
                                    {roomnamelist
                                        .filter(roomname => roomname !== 'id'&& roomname !== 'date') 
                                        .map((roomname, index) => (
                                            <option key={index} value={roomname}>{roomname}</option>
                                        ))
                                    }
                                </select>
                                <br />

                                <center><button type="submit">ยืนยันการลบ</button></center>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RoomDelete;
