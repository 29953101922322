import React, { useRef, useState, useEffect } from 'react';
import Manubar from '../components/Manubar';
import Axios from 'axios';
import $ from "jquery"; 
import ManuHot from '../components/Manuhot';
const MemberEdit = () => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [catsnumber, setCatsnumber] = useState(0);
  const [remark, setRemark] = useState('');
  const [score, setScore] = useState(0);
  const [address, setAddress] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [idnumber, setIdnumber] = useState('');
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
  const [searchPhoneNumber, setSearchPhoneNumber] = useState('');
  const [errortext, setErrorText] = useState('');

  const [lineid, setLineid] = useState('');

  const [memberlist, setMemberlist] = useState([]);

  useEffect(() => {
    Axios.get('https://ikki-cathotel-bangkok.com/php-ikkicathotel/servermemberdelete.php')
        .then(response => {
            console.log("Response data:", response.data);
            setMemberlist(response.data);
        })
        .catch(error => {
            console.error('Error fetching calendar data:', error);
        });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: form.serialize(),
        success: (data) => {
            window.alert("ลบเรียบร้อย");
        },
        error: (xhr, status, error) => {
            window.alert("ลบไม่ได้");
        }
    });
  };

  const handleSelectChange = (event) => {
    const selectedPhoneNumber = event.target.value;
    setId(selectedPhoneNumber);
    const selectedMember = memberlist.find(member => member.phonenumber === selectedPhoneNumber);

    if (selectedMember) {
      setId(selectedMember.id);
      setName(selectedMember.name);
      setSurname(selectedMember.surname);
      setPhonenumber(selectedMember.phonenumber);
      setIdnumber(selectedMember.idnumber);
      setLineid(selectedMember.lineid);
      setAddress(selectedMember.address);
      setCatsnumber(selectedMember.catsnumber);
      setScore(selectedMember.score);
      setRemark(selectedMember.remark);
    }
  };

 
  const handleSearchChange = (event) => {
    setSearchPhoneNumber(event.target.value);

    const foundMember = memberlist.find(member => member.phonenumber === event.target.value);
    if (foundMember) {
        setErrorText('');
        setId(foundMember.id);
        setName(foundMember.name);
        setSurname(foundMember.surname);
        setPhonenumber(foundMember.phonenumber);
        setIdnumber(foundMember.idnumber);
        setLineid(foundMember.lineid);
        setAddress(foundMember.address);
        setCatsnumber(foundMember.catsnumber);
        setRemark(foundMember.remark);


    } else {
        setErrorText('member not found');
        setId('');
        setName('');
        setSurname('');
        setPhonenumber('');
        setIdnumber('');
        setLineid('');
        setAddress('');
        setCatsnumber('');
        setRemark('');
  
    }
};
  const filteredMembers = memberlist.filter(member => {
    return searchPhoneNumber && member.phonenumber ;
  });
  

  return (
    <div className="row">
    <div className="col-6 col-md-2" style={{ backgroundColor: 'pink' }}>
      <Manubar/>
    </div>
      
    <div className="col" style={{ backgroundColor: 'white' }}>
    <ManuHot/>
    <p>ค้นหาสมาชิกด้วยเบอร์โทร</p>
                <input
                    type="text"
                    className="form-control"
                    id="searchphonenumber"
                    value={searchPhoneNumber}
                    onChange={handleSearchChange}
                />
                <div className="col" style={{ color: 'red', padding: '10px' }}>{errortext}</div>
                {/* <div className="column bg-light d-flex justify-content-center align-items-center" style={{ padding: "1%", marginTop: "1%", marginBottom: "1%" }}>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>ชื่อ: {name}</p>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>นามสกุล: {surname}</p>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>เบอร์โทรศัพท์: {phonenumber}</p>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>รหัสประจำตัว: {idnumber}</p>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>LINE ID: {lineid}</p>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>ที่อยู่: {address}</p>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>จำนวนน้องแมว: {catsnumber}</p>
                </div> */}


      <select className="form-select mb-3" onChange={handleSelectChange} value={selectedPhoneNumber }>
        <option value="">เบอร์โทรศัพท์</option>
        {filteredMembers.map((val, key) => (
          <option key={key} value={val.phonenumber}>
            Member Phone: {val.phonenumber}
          </option>
        ))}
      </select>
       
       

        <div className="container-sm p-4 rounded bg-light shadow d-flex flex-column align-items-center" style={{ marginRight: "10%" , marginLeft: "10%" ,width:"80%" }}>
                <center><h1>ลบสมาชิก </h1></center>
                
                <div className='row mb-3'style={{width: '90%'}}>
                    <form
                        action="https://ikki-cathotel-bangkok.com/php-ikkicathotel/servermemberdelete.php"
                        method="post"
                        onSubmit={handleSubmit}
                        style={{width: '90%'}}
                    >
                      <label htmlFor="id"style={{ width: '35%', padding: '2px', margin: '1px', marginLeft: '10%',fontSize:'25px'}}>ID: </label>
                        <input
                            style={{width: '50%'}}
                            type="text"
                            id="id"
                            name="id"
                            value={id}
                            readOnly
                        />
                        <br />
                      <label htmlFor="name"style={{ width: '35%', padding: '2px', margin: '1px', marginLeft: '10%',fontSize:'25px'}}>ชื่อ: </label>
                        <input
                            style={{width: '50%'}}
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <br />
                        <label htmlFor="surname" style={{ width: '35%', padding: '2px', margin: '1px', marginLeft: '10%',fontSize:'25px' }}>นามสกุล: </label>
                        <input
                            style={{width: '50%'}}
                            type="surname"
                            id="surname"
                            name="surname"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                        />
                        <br />
                        <label htmlFor="idnumber" style={{ width: '35%', padding: '2px', margin: '1px', marginLeft: '10%' ,fontSize:'25px' }}>รหัสประจำดัวผู้เสียภาษี: </label>
                        <input
                            style={{width: '50%'}}
                            type="idnumber"
                            id="idnumber"
                            name="idnumber"
                            value={idnumber}
                            onChange={(e) => setIdnumber(e.target.value)}
                        />
                        <br />
                        <label htmlFor="phonenumber" style={{ width: '35%', padding: '2px', margin: '1px' , marginLeft: '10%',fontSize:'25px'}}>เบอร์โทรศัพท์: </label>
                        <input
                            style={{width: '50%'}}
                            type="text"
                            id="phonenumber"
                            name="phonenumber"
                            value={phonenumber}
                            onChange={(e) => setPhonenumber(e.target.value)}
                        />
                        <br />
                        <label htmlFor="catsnumber" style={{width: '35%', padding: '2px', margin: '1px', marginLeft: '10%',fontSize:'25px'}}>จำนวนน้องแมว: </label>
                        <input
                            style={{width: '50%'}}
                            type="text"
                            id="catsnumber"
                            name="catsnumber"
                            value={catsnumber}
                            onChange={(e) => setCatsnumber(e.target.value)}
                        />
                        <br />
                        <label htmlFor="address" style={{ width: '35%', padding: '2px', margin: '1px', marginLeft: '10%',fontSize:'25px'}}>ที่อยู่: </label>
                        <input
                            style={{width: '50%'}}
                            type="text"
                            id="address"
                            name="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                        <br />
                        <label htmlFor="lineid" style={{width: '35%', padding: '2px', margin: '1px' , marginLeft: '10%',fontSize:'25px'}}>LINE ID: </label>
                        <input
                            style={{width: '50%'}}
                            type="text"
                            id="lineid"
                            name="lineid"
                            value={lineid}
                            onChange={(e) => setLineid(e.target.value)}
                        />
                        <br />
       
                        <label htmlFor="remark" style={{width: '35%', padding: '2px', margin: '1px' , marginLeft: '10%',fontSize:'25px'}}>เพิ่มเติม: </label>
                        <input
                            style={{width: '50%'}}
                            type="text"
                            id="remark"
                            name="remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                        />
                         <center><button type="submit" class="btn btn-light" style={{width: '20%' , margin:'1%' , padding:'1%' , backgroundColor:'pink' }}>ยืนยัน</button></center>
                    </form>
                </div>
            </div>
      </div>
    </div>
  );
}

export default MemberEdit;
