import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';


function ManuHot() {

  const navigate = useNavigate();


  const handleBookingManagementClick = () => {
    navigate('/booking/manament');
  };

  const handleMemberCreateClick = () => {
    navigate('/member/create');
  };

  const handleInvoicePrintClick = () => {
    navigate('/invoice/print');
  };
  const handleTohome = () => {
    navigate('/dashboard');
  };


  return (
  
    <div className="col"  style={{ width: "100%" ,backgroundColor:"white" }}>
    <div className="row-2" style={{ backgroundColor: 'white' , display:'flex'  ,width:'100%',justifyContent:'space-around'}}>

      <button className='btn btn-light' type="button" onClick={handleMemberCreateClick} style={{ margin: "5%" }}> 
        <img src="https://ikki-cathotel-bangkok.com/images/but001.jpg"   alt="Booking" style={{ width: "100%" }} />
      </button>

      <button className='btn btn-light' type="button" onClick={handleBookingManagementClick} style={{ margin: "5%" }}> 
        <img src="https://ikki-cathotel-bangkok.com/images/but002.jpg" alt="Booking" style={{ width: "100%" }} />
      </button>
      <button className='btn btn-light' type="button" onClick={handleInvoicePrintClick} style={{ margin: "5%" }}> 
        <img src="https://ikki-cathotel-bangkok.com/images/but003.jpg"   alt="Booking" style={{ width: "100%" }} />
      </button>

      <button  className='btn btn-light' type="button" onClick={handleTohome} style={{ margin: "5%" }}> 
        <img src="https://ikki-cathotel-bangkok.com/images/but004.jpg" alt="Booking" style={{ width: "100%" }} />
      </button>
    
    </div>

  </div>
    
  );
}

export default ManuHot;

