import React, {  useState } from 'react';

import $ from "jquery"; 


const Bookingcancel = ({id ,checkindate , checkoutdate , roomname}) => {


  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: form.serialize(),
        success: (data) => {
            window.alert("ลบข้อมูลเรียบร้อย");
        },
        error: (xhr, status, error) => {
            window.alert("ลงข้อมูลไม่ได้");
        }
    });
  };


  

  



  return (
    <div className="row">
          <div className="col" >
        
      
                    <form
                        action="https://ikki-cathotel-bangkok.com/php-ikkicathotel/serverbookingdelete.php"
                        method="post"
                        onSubmit={handleSubmit}
                        style={{width: '90%'}}
                    >
                   
                        <input
                            type="hidden"
                            id="id"
                            name="id"
                            value={id}
                            readOnly
                            style={{ width: '60%', marginLeft: '20%' }}
                        />
                          <input
                            type="hidden"
                            id="checkindate"
                            name="checkindate"
                            value={checkindate}
                            readOnly
                            style={{ width: '60%', marginLeft: '20%' }}
                        />

                          <input
                            type="hidden"
                            id="checkoutdate"
                            name="checkoutdate"
                            value={checkoutdate}
                            readOnly
                            style={{ width: '60%', marginLeft: '20%' }}
                        />
                          <input
                            type="hidden"
                            id="roomname"
                            name="roomname"
                            value={roomname}
                            readOnly
                            style={{ width: '60%', marginLeft: '20%' }}
                        />
             
                       <center><button type="submit" style={{   borderRadius: '40%' ,backgroundColor:'red' }}>ยกเลิก</button></center> 
                    </form>
            
        </div>
            
         
     
      
    </div>
  );
}

export default Bookingcancel;
