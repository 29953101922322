import React, { useState , useEffect} from "react";
import $ from "jquery"; // Make sure jQuery is installed and properly imported
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Button } from "bootstrap";


function IkkiHomePage() {
    const [showLogin, setShowLogin] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [checkin, setCheckin] = useState("");
    const [checkout, setCheckout] = useState("");
    const [result, setResult] = useState([]);
    const [lineid , setLineid]= useState("");
    const [remark , setRemark]= useState("");

    const navigate = useNavigate();

    useEffect(() => {
      setShowLogin(true); // Set showLogin to true when component mounts
  }, []); 

    const handlepagelogin = () => {
      navigate('pagelogin');
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const form = $(e.target);
      $.ajax({
          type: "POST",
          url: form.attr("action"),
          data: form.serialize(),
          success(data) {
              try {
                  const parsedData = JSON.parse(data); // Parse the JSON response
                  setResult(parsedData); // Set the result to the token received
                  window.alert("คำขอถูกส่งเรียบร้อยแล้ว");
                  setName("");
                  setPhone("");
                  setCheckin("");
                  setCheckout("");
                  setLineid("");
                  setRemark("");
                  
              } catch (error) {
                  console.error("Error parsing response:", error);
              }
          },
          error(jqXHR, textStatus, errorThrown) {
              console.error("AJAX Error:", errorThrown);
          }
          
      });
      window.alert("คำขอถูกส่งเรียบร้อยแล้ว");
      setName("");
      setPhone("");
      setCheckin("");
      setCheckout("");
      setLineid("");
      setRemark("");
      
  };
  

  return (
    <div>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: "#FFC6C6" ,Color: "white", textAlign:"center"}}>
        <div className="container">
          <a className="navbar-brand" href=""> <img src="https://img5.pic.in.th/file/secure-sv1/logoacf5d83ccd5bb7b2.png" style={{width:"50px"}} alt="Description of your image" className="img-fluid rounded" /></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0" >
                 
                      <button className="nav-item" style={{ color: "white" ,background:"pink" , padding:"5px" , borderRadius: "20px", borderColor: "white"}} onClick={handlepagelogin}>
                          LOGIN
                      </button>
                 
              </ul>
          </div>
        </div>
      </nav>

      {/* Page content */}
      <div className="container mt-5">
        <div className="row" >
          <center>
                <figure className="mb-4" style={{width:"50%"}}><img className="img-fluid rounded" src="https://www.ikki-cathotel-bangkok.com/images/logo.jpg" alt="..." /></figure>
                <h3 className="fw-bolder mb-1"> ยินดีต้อนรับเข้าสู่ </h3><br/>
                <h1 className="fw-bolder mb-1"> IKKI CAT HOTEL Bangkok</h1><br/>
                <h3 className=" mb-1" > 
                <p>ให้การเดินทางเพื่อหาที่พัก<br/>ให้กับน้องแมวของคุณ <br/>ไม่ต้องเป็นปัญหาอีกต่อไป</p>
                <p>โรงแรมแมวของเราพร้อม<br/>ต้อนรับน้องแมวของคุณ <br/>ด้วยบริการที่ดีเยี่ยมและ <br/>
                สภาพแวดล้อมที่เหมาะสม <br/>สำหรับการพักผ่อนของเด็กๆ </p>
                 <p>เพียบพร้อมทั้งการบริการ <br/>และสิ่งอำนวยความสะดวกครบครัน </p>
                 </h3><br/><br/>
                <h3 className="fw-bolder mb-1" > " นุดท่องเที่ยวสุดสบาย<br/> นายเอนกายพักที่นี่ "  </h3>
                <h3 className=" mb-1" > 
                <p>รับฝากแมว รับดูแลแมว<br/> 
                รายวัน รายเดือน ห้องแอร์ 24ชม. </p>
                <p>สะอาด ปลอดภัย ไม่ขังกรง <br/>
                ชมวิวชมสวนได้ทั้งวัน <br/><b>เริ่มต้นเพียง 350/วัน</b></p>     
                </h3><br/>
                <h3 className="fw-bolder mb-1" >IKKI CAT HOTEL Highlights </h3>
          
         

            <img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/web-picture001.jpg" alt="servicecatdeliverytocathotel" />
            <br/>
            <h3 className=" mb-1" > 
                <p>เหนือกว่าทุกที่ด้วย <br/>Facilities แบบเบิ้มๆ <br/>
                ห้องพักแบบ Vertical Unit <br/>
                เข้าใจพฤติกรรมเด็กๆ</p>
                <p>เปิดเครื่องปรับอากาศ 24Hr.<br/> พร้อมระบบอากาศแบบปิดแยกห้อง <br/>ที่เดียวในไทย (กรองอากาศ+UV) </p>
                <p>นอกจากนั้น<br/> ยังสามารถชมวิวสวนสวยได้ทุกห้อง<br/> ชมนก , กระรอก , ต้นไม้ <br/>และ ท้องฟ้าได้ตลอดเวลา  </p>
                <p>อุ่นใจด้วยระบบประตู 3 ชั้น <br/> สแกนใบหน้า และ <br/>ผู้ปกครองสามารถดูน้องๆ  </p>
                <p>ด้วยระบบกล้องวงจรปิด <br/>ภายในห้องพักได้ตลอด 24Hr <br/> พร้อมส่วนกลาง </p>
            </h3><br/> 
            <h3 className="fw-bolder mb-1" > Active Zone จัดเต็มของเล่น <br/>และ กิจกรรมสันทนาการ   </h3><br/>
            <h3 className=" mb-1" > จากพี่เลี้ยง เพื่อให้เด็กๆอยู่กับเรา<br/>อย่างมีความสุขในทุกวัน  </h3><br/><br/>
            <h2 className="fw-bolder mb-1" > ประเภทห้องพักของเรา <br/> Room types </h2>
            
            <img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/web-room01.jpg" alt="servicecatdeliverytocathotel" />
            <h3 className=" mb-1" > เหมาะสำหรับน้องแมว 1 ตัว<br/> (ตัวที่2 +50บาท/วัน)  </h3><br/>

            <img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/web-room02.jpg" alt="servicecatdeliverytocathotel" />
            <h3 className=" mb-1" > เหมาะสำหรับน้องแมว 2 ตัว <br/>(ตัวที่3 +50บาท/วัน) </h3><br/>

            <img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/web-room03.jpg" alt="servicecatdeliverytocathotel" />
            <h3 className=" mb-1" > เหมาะสำหรับน้องแมว 3 ตัว <br/>(ตัวที่3 +50บาท/วัน) </h3><br/>
            
            <img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/web-room04.jpg" alt="servicecatdeliverytocathotel" />
            <h3 className=" mb-1" > เหมาะสำหรับน้องแมว 5 ตัวขึ้นไป  </h3><br/>

                        
            <img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/web-room05.jpg" alt="servicecatdeliverytocathotel" />
            <h3 className=" mb-1" > เหมาะสำหรับครอบครัวใหญ่ <br/>น้องแมว 7 ตัวขึ้นไป </h3><br/>
            

            <h1 className="fw-bolder mb-1" > การบริการที่น้องแมว<br/>จะได้รับจากเรา   </h1><br/>
            <h3 className=" mb-1" > 
              <p>ฟรีทรายแมวทุกห้อง <br/>(หรือเจ้าของนำมาเองก็ได้) <br/> น้ำดื่มสะอาดระบบ R/O <br/> พาออกกำลังกาย </p>   
              <p>เดินเล่นในพื้นที่ Active Zone <br/>แยกห้อง วันละ 1-2 รอบ<br/> ตามความพร้อมของเด็กๆ </p>  
              <p>มีพี่เลี้ยงคอยดูแล<br/> เรื่องความเป็นอยู่ในทุกๆวัน <br/>พร้อมรายงานประจำวัน<br/>ให้ผู้ปกครองทราบ </p>     
              <p>ตลอดช่วงเวลาที่น้องเข้าพักกับเรา  </p> 
            </h3><br/>

            <img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/headline_pic01.jpg" alt="servicecatdeliverytocathotel" />
            <h1 className="fw-bolder mb-1" > เงื่อนไขและข้อตกลง <br/>ในการรับฝากแมว </h1><br/>
            <h3 className=" mb-1" > 
                <p> 1. น้องแมวอายุ 3 เดือนขึ้นไป</p>
                <p> 2. ต้องได้รับวัคซีนพื้นฐานครบ<br/> วัคซีนไข้หัด, หวัดแมว ,<br/>
                ป้องกันโรคลิวคิเมีย และ พิษสุนัขบ้า </p>
                <p> 3. ไม่มีเห็บหมัด เชื้อรา ไรในหู<br/> ไม่เป็นเอดส์แมว </p>
                <p> 4. เลี้ยงน้องแมวในระบบปิดเท่านั้น </p>
                <p> 5. หากน้องแมวป่วยในขณะเข้าพัก <br/>ทางโรงแรมจะแจ้งเจ้าของทราบทันที <br/>
                และ นำน้องแมวส่ง คลีนิค หรือ <br/>โรงพยาบาลสัตว์ใกล้เคียง <br/>
                โดยเจ้าของเป็นผู้รับผิดชอบ<br/>ค่าใช้จ่ายทั้งหมด </p>
                <p> 6. หากน้องแมวได้รับบาดเจ็บ หรือ<br/> เสียชีวิตขณะเข้าพัก <br/>
                โดยพิสูจน์ทราบแล้วว่า<br/> ไม่ได้เกิดจากความประมาทเลินเล่อ <br/>ของทางโรงแรม <br/>
                ทางโรงแรมขอสงวนสิทธิ์<br/>ในการรับผิดชอบทุกกรณี </p>
                <p> 7. หากเจ้าของไม่มารับน้องแมว<br/>ตามกำหนด <br/>ทางโรงแรมจะดำเนินการตาม <br/>พรบ. คุ้มครองสัตว์</p>
            </h3><br/>

            <img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/headline_pic02.jpg" alt="servicecatdeliverytocathotel" />
            <h1 className="fw-bolder mb-1" > การสำรองห้องพัก</h1><br/>
            <h3 className=" mb-1" > 
                <p> 1. สามารถติดต่อผ่านแบบฟอร์ม<br/>ด้านล่างของเวปไซส์<br/> เพื่อให้เจ้าหน้าที่ติดต่อกลับ</p>
                <p> 2. สามารถติดต่อผ่านช่องทาง<br/>ออนไลน์ต่างๆ ทั้ง <br/>Inbox Facebook , Line OA <br/>หรือ โทรศัพท์มือถือ</p>
                <p> 3. ชำระมัดจำค่าห้อง 50% <br/>ถือว่าการจองเสร็จสมบูรณ์ <br/>ยอดที่เหลือทั้งหมดชำระ <br/>ณ วันเช็คอิน </p>
                <p> 4. เปิดให้เช็คอินได้ตั้งแต่<br/>เวลา 8.30 - 20.00 <br/>นอกเหนือเวลาทำการ <br/>กรุณาแจ้งทางโรงแรมล่วงหน้า </p>
                <p> 5. การยกเลิกการจอง <br/>ต้องแจ้งให้ทางโรงแรมทราบ<br/>ล่วงหน้า 10 วัน (คืนเงินเต็มจำนวน)</p>
                <p> 6. กรณียกเลิกการจอง <br/>น้อยกว่า 7 วัน  ทางโรงแรม <br/>ขอสงวนสิทธิ์ในการคืนเงิน</p>
                <p> 7. กรณีเลื่อนวันเข้าพัก <br/>ทางโรงแรมอนุญาตให้เลื่อนได้<br/> 1 ครั้ง และต้องทำการเช็คอินใหม่</p>
                <p> 8. ทางโรงแรมบริหารกิจการ<br/>แบบนิติบุคคล <br/>สามารถออกใบกำกับภาษี<br/>เต็มรูปให้ได้</p>
            </h3>

            <img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/headline_pic03.jpg" alt="servicecatdeliverytocathotel" />
            <h1 className="fw-bolder mb-1" > สิ่งที่ต้องเตรียมมาก่อน<br/>เช็คอินเข้าพัก</h1><br/>
            <h3 className=" mb-1" > 
                <p> 1. นัดหมาย วัน-เวลาเข้าพัก<br/>ก่อนทุกครั้ง <br/>ทางโรงแรมไม่รับลูกค้า Walk in</p>
                <p> 2. บัตรประชาชนตัวจริง <br/>หรือ สำเนาบัตรประชาชนผู้ปกครอง <br/>เพื่อใช้ในการทำสัญญาเข้าพัก</p>
                <p> 3. สมุดวัคซีนตัวจริงของเด็กๆ  </p>
                <p> 4. อาหาร ขนม ของเล่นส่วนตัว<br/> ผ้าปู ที่เด็กๆ ทาน หรือ ติดกลิ่น </p>
                <p> 5. ทรายแมวในปริมาณที่เหมาะสม<br/> (กรณีไม่ได้เตรียมมา <br/>ทางโรงแรมมีบริการฟรี)</p>
                <p> 6. การนำส่งเด็กๆ ต้องใส่กรง หรือ<br/> ตระกร้ามิดชิดทุกครั้ง</p>

            </h3><br/><br/>

           

            <h3 className="fw-bolder mb-1" > บริการเสริม</h3>
            <img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/services01.jpg" alt="servicecatdeliverytocathotel" />
            <h3 className="fw-bolder mb-1" > สถานที่ตั้ง </h3><br/>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.395879790858!2d100.55197027586644!3d13.875261294421641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e283f2397d1d1f%3A0xb06288e05079b200!2z4LmC4Lij4LiH4LmB4Lij4Lih4LmB4Lih4Lin4LiB4Lij4Li44LiH4LmA4LiX4LieIElLS0kgQ2F0IEhvdGVsIEJhbmdrb2s!5e0!3m2!1sth!2sth!4v1714535256809!5m2!1sth!2sth" width="90%" height="450" ></iframe>
            <a href="https://maps.app.goo.gl/2iuRX67bGpMGgLUPA" ><img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/mapIKKI.jpg" alt="servicecatdeliverytocathotel" /></a>
            <h1>ช่องทางการติดต่อ / Contact</h1>
            <a href="tel:0814477646" ><img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/contact05.jpg" alt="servicecatdeliverytocathotel" /><br/></a>
            <a href="http://m.me/IKKI.CatHotel.Bangkok" ><img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/contact01.jpg" alt="servicecatdeliverytocathotel" /><br/></a>
            <a href="https://lin.ee/4ZGOi52" ><img className="img-fluid rounded" src="https://ikki-cathotel-bangkok.com/images/contact02.jpg" alt="servicecatdeliverytocathotel" /></a>
                          .
              <div className=" mb-1" style={{color:'white'}}> 
              รับฝากแมว ดูแลแมว  รับเลี้ยงแมว  โรงแรมแมว  กรุงเทพ  CatHotel  bangkok  Condo  คอนโด แมว cat
              ท่องเที่ยว เดินทาง ต่างประเทศ  ญี่ปุ่น  เกาหลี  สหรัฐอเมริกา  จีน เวียดนาม ฮ่องกง สิงค์โปร์ ยุโรป
              อาหารแมว ขนมแมว ขนมแมวเลีย ทรายแมว ตั๋วเครื่องบิน เที่ยวบิน แอร์เอเชีย การบินไทย 
              นกแอร์ สายการบิน ประกันภัย  Thai Airways  Air asia</div>
            </center>
       
        </div>
      </div>

      {/* Footer */}
      <footer className="py-5 bg-pink" style={{backgroundColor: "#FFC6C6" ,Color: "white"}}>
        <div className="container"><p className="m-0 text-center text-white">Copyright &copy; Ikki Cat Hotel 2024</p></div>
      </footer>

 
    </div>
  );
}

export default IkkiHomePage;

