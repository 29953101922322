import React, { useState, useEffect } from "react";
import Axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Manubar from "../components/Manubar";
import BookingPost from "../components/bookingpost";
import BookCalendar from "../components/bookcalendar";
import ManuHot from "../components/Manuhot";

const BookingManagement = () => {
    const [calendarData, setCalendarData] = useState([]);
    const [checkindate, setCheckindate] = useState('');
    const [checkoutdate, setCheckoutdate] = useState('');
    const [columnNames, setColumnNames] = useState([]);
    const [id, setId] = useState('');
    const [bookingid, setBookingID] = useState('');
    const [roomname, setRoomname] = useState('');
    const [roomnamenobook, setRoomnamenobook] = useState('');
    const [memberlist, setMemberlist] = useState([]);
    const [selectedMemberId, setSelectedMemberId] = useState('');
    const [fullprice, setFullprice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [priceVat, setPriceVat] = useState(0);
    const [price, setPrice] = useState(0);
    const [remark, setRemark] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [idnumber, setIdNumber] = useState('');
    const [lineid, setLineid] = useState('');
    const [address, setAddress] = useState('');
    const [catsnumber, setCatsnumber] = useState(0);
    const [score, setScore] = useState(0);
    const [addscore, setAddScore] = useState(0);
    const [prevscore, setPrevScore] = useState(0);
    const [searchPhoneNumber, setSearchPhoneNumber] = useState('');
    const [errortext, setErrorText] = useState('');
    const [details, setDetails] = useState('');
    const [status, setStatus] = useState('Booking');
    const [days, setDays] = useState(0);
    const checkinDateNumber = checkindate ? format(new Date(checkindate), 'yyyyMMdd') : '';
    const checkoutDateNumber = checkoutdate ? format(new Date(checkoutdate), 'yyyyMMdd') : '';



    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5; // Number of columns to display per page
    const totalPages = Math.ceil(columnNames.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        Axios.get('https://www.ikki-cathotel-bangkok.com/php-ikkicathotel/serverroomscontrol.php')
            .then(response => {
                console.log("Response data:", response.data);
                setCalendarData(response.data);
                // Extract column names from the first entry
                if (response.data.length > 0) {
                    const firstEntry = response.data[0];
                    const columns = Object.keys(firstEntry).filter(column => column !== 'id' && column !== 'date');
                    setColumnNames(columns);
                }
            })
            .catch(error => {
                console.error('Error fetching calendar data:', error);
            });
    }, []);

    useEffect(() => {
        Axios.get(`https://www.ikki-cathotel-bangkok.com/php-ikkicathotel/severmembercreate.php`)
            .then((response) => {
                setMemberlist(response.data);
                if (response.data.length > 0) {
                    const lastMemberId = response.data[response.data.length - 1].id;
                    setSelectedMemberId(lastMemberId);
                }
            })
            .catch((error) => {
                console.error("Error fetching member data:", error);
            });
    }, []);

    const handleSearchChange = (event) => {
        setSearchPhoneNumber(event.target.value);

        const foundMember = memberlist.find(member => member.phonenumber === event.target.value);
        if (foundMember) {
            setErrorText('');
            setId(foundMember.id);
            setName(foundMember.name);
            setSurname(foundMember.surname);
            setPhonenumber(foundMember.phonenumber);
            setIdNumber(foundMember.idnumber);
            setLineid(foundMember.lineid);
            setAddress(foundMember.address);
            setCatsnumber(foundMember.catsnumber);
            setRemark(foundMember.remark);
  

        } else {
            setErrorText('member not found');
            setId('');
            setName('');
            setSurname('');
            setPhonenumber('');
            setIdNumber('');
            setLineid('');
            setAddress('');
            setCatsnumber('');
            setRemark('');
      
        }
    };

    const filteredDataInRange = calendarData.filter(entry => {
        const currentDate = new Date(entry.date);
        return currentDate >= checkindate && currentDate <= checkoutdate;
    });

    const formatDate = (dateString) => {
        if (!dateString) {
            return ''; // Return an empty string if dateString is null or undefined
        }
        return format(new Date(dateString), 'MMMMddyyyy');
    };

    useEffect(() => {
        setBookingID(`${roomname}${phonenumber}${checkinDateNumber}${checkoutDateNumber}`);
    }, [roomname, phonenumber, checkindate, checkoutdate]);

    const countEmptyCellsByColumn = () => {
        const emptyCellCounts = {};
        // Initialize empty cell counts for each column
        columnNames.slice(startIndex, endIndex).forEach((columnName) => {
            emptyCellCounts[columnName] = 0;
        });
        // Iterate through each row
        filteredDataInRange.forEach((entry) => {
            // Iterate through each column in the row
            columnNames.slice(startIndex, endIndex).forEach((columnName) => {
                // Check if the cell value is empty
                if (!entry[columnName]) {
                    emptyCellCounts[columnName]++;
                }
            });
        });
        return emptyCellCounts;
    };

    // State to hold the counts of empty cells by column
    const [emptyCellCounts, setEmptyCellCounts] = useState({});

    // Update empty cell counts when filteredDataInRange or columnNames change
    useEffect(() => {
        const counts = countEmptyCellsByColumn();
        setEmptyCellCounts(counts);
    }, [filteredDataInRange, columnNames, startIndex, endIndex]);

    useEffect(() => {
        setDays((checkoutdate-checkindate)/86400000);
       
    }, [checkindate , checkoutdate]);

    return (
        <div className="row">
            <div className="col-6 col-md-2" style={{ backgroundColor: 'pink' }}>
                <Manubar />
            </div>
            <div className="col" style={{ backgroundColor: 'white', padding: '5px', width: '80%' }}>
                <ManuHot />
                <br />
                <p>ค้นหาสมาชิกด้วยเบอร์โทร</p>
                <input
                    type="text"
                    className="form-control"
                    id="searchphonenumber"
                    value={searchPhoneNumber}
                    onChange={handleSearchChange}
                />
                <div className="col" style={{ color: 'red', padding: '10px' }}>{errortext}</div>
                <div className="column bg-light d-flex justify-content-center align-items-center" style={{ padding: "1%", marginTop: "1%", marginBottom: "1%" }}>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>ชื่อ: {name}</p>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>นามสกุล: {surname}</p>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>เบอร์โทรศัพท์: {phonenumber}</p>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>รหัสประจำตัว: {idnumber}</p>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>LINE ID: {lineid}</p>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>ที่อยู่: {address}</p>
                    <p style={{ padding: "0.5%", margin: "0.5%" }}>จำนวนน้องแมว: {catsnumber}</p>
                </div>
                <div className="column bg-light d-flex align-items-center" style={{ padding: "1%", marginTop: "1%", marginBottom: "1%", justifyContent: "space-around" }}>
                    <div className="mb-3" style={{ padding: "0.5%", marginTop: "0.5%", marginBottom: "0.5%", width: "20%" }}>
                        CHECK IN: <DatePicker
                            selected={checkindate}
                            onChange={(date) => setCheckindate(date)}
                            className="form-control" />
                    </div>
                    <div className="mb-3" style={{ padding: "0.5%", marginTop: "0.5%", marginBottom: "0.5%", width: "20%" }}>
                        CHECK OUT: <DatePicker
                            selected={checkoutdate}
                            onChange={(date) => setCheckoutdate(date)}
                            minDate={checkindate}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="tab-bar">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index)}
                            className={index === currentPage ? 'active' : ''}
                        >
                            Page {index + 1}
                        </button>
                    ))}
                </div>
                <table className="table" style={{ padding: "0px", margin: "0px", borderCollapse: "collapse", tableLayout: "fixed", textAlign: "center" }}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            {columnNames.slice(startIndex, endIndex).map((columnName, index) => (
                                <th key={index}>{columnName}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDataInRange.map((entry, index) => {
                            const formattedDate = formatDate(entry.date);
                            return (
                                <tr key={index}>
                                    <td>{formattedDate}</td>
                                    {columnNames.slice(startIndex, endIndex).map((columnName, columnIndex) => (
                                        columnName ? (
                                            <td style={{ fontSize: "12px" }} key={columnIndex}>{entry[columnName]}</td>
                                        ) : null
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table><br />
                <div className='col'>
       
                    <select 
                        className="calendar" 
                        id="roomname" 
                        value={roomname} 
                        onChange={(e) => setRoomname(e.target.value)}
                        disabled={!columnNames.some(columnName => emptyCellCounts[columnName] === days)} 
                    >
                        <option value="">Select a room</option>
                        {columnNames.map((columnName, index) => (
                            emptyCellCounts[columnName] === days && (
                                <option key={index} value={columnName}>
                                    {columnName}
                                </option>
                            )
                        ))}
                    </select>
                </div>
                <label
                    style={{ width: '80%', padding: '2px', margin: '1px', minWidth: "200px" }}
                    type="text"
                    id="bookingid"
                    name="bookingid"
                    readOnly
                />
                <br />
                <center>BookingID: {bookingid}</center><br />
                <BookingPost checkindate={checkinDateNumber} checkoutdate={checkoutDateNumber} roomname={roomname} bookingid={bookingid} phonenumber={phonenumber} name={name} surname={surname} idnumber={idnumber} days={days} address={address}/>
                <br />
                <BookCalendar checkindate={checkinDateNumber} checkoutdate={checkoutDateNumber} roomname={roomname} bookingid={bookingid} />
            </div>
        </div>
    );
};

export default BookingManagement;
